import { FileSystem } from './FileSystem.js';
import { SystemLogger } from './SystemLogger.js';
import { IDBStorage } from './IDBStorage.js';

import { getDefaultEspressivoProjectFile } from '../misc/default-project-file.js';

// Espressivo project directory format:
// - directory 'Assets' (contains all assets, i.e. audio files)
// - directory 'Builds' (contains compiled versions to be shared with programmers)
// - one or more files '*.espressivo' (Espressivo project files)

export class EspressivoProjectHelper {

  static obligatoryDirectories = [
    'Assets',
    'Builds',
  ];

  // File System Access stuff:
  static async openExistingEspressivoProject () {
    let selectedDirectory;

    try {
      SystemLogger.log('Attempting to use File System Access API to open directory');
      selectedDirectory = await FileSystem.chooseDirectory();
      SystemLogger.log('Successfully used File System Access API to open directory');
    }
    catch (err) {
      if (err instanceof DOMException && err.name === 'AbortError') {
        SystemLogger.log('User aborted opening an existing project');
        return null;
      }
      else {
        SystemLogger.log('File System Access API errored');
        throw err;
      }
    }

    // If code above did not error, 'selectedDirectory' will be set.
    if (selectedDirectory) {
      // Line below can error, but we won't catch it, so callee can display error messages to the user.
      return await this.openAndStoreDirectory(selectedDirectory);
    }
  }

  static async createNewEspressivoProject (projectName) {
    let directoryToSaveInto;

    try {
      SystemLogger.log('Attempting to use File System Access API to open directory');
      directoryToSaveInto = await FileSystem.chooseDirectory();
      SystemLogger.log('Successfully used File System Access API to open directory');
    }
    catch (err) {
      if (err instanceof DOMException && err.name === 'AbortError') {
        SystemLogger.log('User aborted opening an existing project');
        return null;
      }
      else {
        SystemLogger.log('File System Access API not supported');
        throw err;
      }
    }

    // If code above did not error, 'directoryToSaveInto' will be set.
    if (directoryToSaveInto) {
      // FIXME: createProjectStructure will throw errors if directory already existed. Fix error handling.
      const newlyCreatedDirectory = await this.createProjectStructure(directoryToSaveInto, projectName);
      // Line below can error, but we won't catch it, so callee can display error messages to the user.
      return await this.openAndStoreDirectory(newlyCreatedDirectory);
    }
  }

  static async createProjectStructure (directoryToSaveInto, projectName) {
    // Check if a directory named {projectName} already exist in {directoryToSaveInto} before creating project.
    if (await FileSystem.directoryExists(projectName, directoryToSaveInto) === true) {
      throw new Error('Directory with that name already exist');
    }

    // First create the project main directory.
    const newlyCreatedProjectDir = await directoryToSaveInto.getDirectoryHandle(projectName, {
      create: true,
    });

    // Then create its contents.
    const creationPromises = [];

    // Create project file and keep reference to handle.
    let projectFileHandle;

    creationPromises.push(
      newlyCreatedProjectDir.getFileHandle(`${projectName}.espressivo`, { create: true }).then((fileHandle) => {
        projectFileHandle = fileHandle;
      }),
    );

    // Create obligatory directories.
    for (const obligatoryDirectory of this.obligatoryDirectories) {
      creationPromises.push(newlyCreatedProjectDir.getDirectoryHandle(obligatoryDirectory, { create: true }));
    }

    // Wait for all this to finish.
    await Promise.all(creationPromises);

    // Save default project file into project main directory.
    await this.saveProjectFile(projectFileHandle, getDefaultEspressivoProjectFile());

    return newlyCreatedProjectDir;
  }

  static async saveProjectFile (projectFileHandle, projectFileContents) {
    // Create a writer.
    const writer = await projectFileHandle.createWritable();
    // Write the full length of the contents.
    await writer.write(projectFileContents);
    // Close the file and write the contents to disk.
    await writer.close();
  }

  static async openAndStoreDirectory (dir) {
    const treeStructure = await FileSystem.getTreeStructure(dir);
    const isValidEspressivoProjectDirectory = this.validateTreeStructure(treeStructure);

    if (isValidEspressivoProjectDirectory === true) {
      SystemLogger.log('Directory is valid Espressivo project directory');
    }
    else {
      SystemLogger.log('Directory is not a valid Espressivo project directory');
      // REVIEW: Is this the way we want to throw custom errors? Or by extending Error with our own Error types?
      throw new DOMException('Directory is not a valid Espressivo project directory', 'DataError');
    }

    // Try storing the directory handle in IndexedDB
    try {
      await IDBStorage.storeProjectDirectoryHandle(dir);
    }
    catch (err) {
      SystemLogger.log(`Couldn't save directory handle`);
      throw err;
    }

    return treeStructure;
  }

  static validateTreeStructure (treeStructure) {
    // Check for obligatory base level directories.
    for (const obligatoryDirectory of this.obligatoryDirectories) {
      // TODO: Also check if these are all directories (not files).
      if (!treeStructure.hasChildNamed(obligatoryDirectory)) return false;
    }

    // Check if there's at least one .espressivo project file at base level.
    if (!this.getProjectFileTreeStructureItems(treeStructure).length) return false;

    // If all checks have passed, directory is valid Espressivo project directory
    return true;
  }

  static getProjectFileTreeStructureItems (projectDirectoryTreeStructure) {
    return [...projectDirectoryTreeStructure]
      .filter(FileSystem.isFileTreeStructureItem)
      .filter(fileEntry => fileEntry.fileType === '.espressivo');
  }

  // TODO: This is temporary, just until there's a project version picker or something.
  static async getMostRecentProjectFileTreeStructureItem (projectDirectoryTreeStructure) {
    const projectFiles = [];

    for (const projectFileTreeStructureItem of this.getProjectFileTreeStructureItems(projectDirectoryTreeStructure)) {
      projectFiles.push({
        treeStructureItem: projectFileTreeStructureItem,
        file: await projectFileTreeStructureItem.getHandle().getFile(),
      });
    }

    // REVIEW: Check if this works correctly after this bugfix has been deployed: https://bugs.chromium.org/p/chromium/issues/detail?id=101894
    const sortedProjectFiles = projectFiles.sort(({ file: fileA }, { file: fileB }) => {
      return fileA.lastModified - fileB.lastModified;
    });

    return sortedProjectFiles[0];
  }

  // TODO: This is temporary, just until there's a project version picker or something.
  // static async getMostRecentProjectFileContents (projectDirectoryTreeStructure) {
  //   const projectFiles = [];

  //   for (const projectFileTreeStructureItem of this.getProjectFileTreeStructureItems(projectDirectoryTreeStructure)) {
  //     projectFiles.push({
  //       treeStructureItem: projectFileTreeStructureItem,
  //       file: await projectFileTreeStructureItem.getHandle().getFile(),
  //     });
  //   }

  //   // REVIEW: Check if this works correctly after this bugfix has been deployed: https://bugs.chromium.org/p/chromium/issues/detail?id=101894
  //   const sortedProjectFiles = projectFiles.sort(({ file: fileA }, { file: fileB }) => {
  //     return fileA.lastModified - fileB.lastModified;
  //   });

  //   const {
  //     treeStructureItem: latestProjectFileTreeStructureItem,
  //     file: latestProjectFile,
  //   } = sortedProjectFiles[0];
  // }

  static validateProjectData (projectData) {
    // TODO: Use JSON Schema or something to validate project data JSON
    return typeof projectData === 'object';
  }

}