import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';

import { systemStore, systemPlugin } from './store/modules/systemStore.js';
import { appSettingsStore } from './store/modules/appSettingsStore.js';
import { projectsManagerStore } from './store/modules/projectsManagerStore.js';
import { projectStore } from './store/modules/projectStore.js';
import { SystemLogger } from './system/SystemLogger.js';

// import { undoRedoHistory } from './store/plugins/undo-redo-history.js';

Vue.use(Vuex);

export const store = new Vuex.Store({

  strict: (process.env.NODE_ENV === 'development'),

  plugins: [
    createLogger({
      logger: SystemLogger,
    }),
    systemPlugin,
    // NOTE: Turned off undo/redo for now
    // undoRedoHistoryPlugin,
  ],

  modules: {
    system: systemStore,
    settings: appSettingsStore,
    projectsManager: projectsManagerStore,
    project: projectStore,
  },

});

// function undoRedoHistoryPlugin (store) {
//   undoRedoHistory.init(store);
//   const initialState = cloneDeep(store.state);
//   undoRedoHistory.addState(initialState);

//   store.subscribe((mutation, state) => {
//     // is called AFTER every mutation
//     // TODO: we don't want this to work like this
//     undoRedoHistory.addState(cloneDeep(state));
//     console.log(undoRedoHistory.history);
//   });
// };