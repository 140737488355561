const appName = process.env.VUE_APP_NAME,
      appVersion = process.env.VUE_APP_VERSION;

const configPrefix = `${appName.toLowerCase().replace(/\s/g, '.')}.`;

let debugPreference = localStorage.getItem(`${configPrefix}debug`);
if (debugPreference !== null) {
  debugPreference = (debugPreference === 'true' || debugPreference === true) ? true : false;
}

export const config = Object.freeze({
  debug: debugPreference ?? (process.env.NODE_ENV === 'development'),
  appName,
  appVersion,
  configPrefix,
  mail: {
    contact: 'contact@espressivo.io',
    help: 'help@espressivo.io',
  },
});