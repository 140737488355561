/**
 * Dashboard wishlist:
 * - All projects, with sorting and searching capabilities
 * - Recent projects
 * - Starred projects
 * - Your account settings
 * - ...
 */
export const Dashboard = {

  name: 'dashboard',

  template:  `<main class="dashboard">
                <dashboard-actions/>
                <recent-projects/>
              </main>`,

};