import { mapState } from 'vuex';

export const PixelDensityReactiveCanvasMixin = {

  computed: {
    ...mapState('system', [
      'devicePixelRatio',
    ]),
  },

  watch: {
    'devicePixelRatio' () {
      this.$nextTick(() => {
        this.draw();
      });
    },
  },

};