import Vue from 'vue';
import { FileSystem } from '../../system/FileSystem.js';

Vue.component('asset-file', {

  props: {
    'assetName': {
      type: String,
      required: true,
    },
    'assetFileTreeStructureItem': {
      type: Object,
      required: true,
    },
  },

  template:  `<span class="asset-file">♫ {{ assetName }}</span>`,

});