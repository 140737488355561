import { FileSystem } from '../system/FileSystem.js';
import { ModalMixin } from '../mixins/ModalMixin.js';
import { ModalUnknownError } from './ModalUnknownError.js';

export const ModalCreateProject = {

  name: 'modal-create-project',

  mixins: [
    ModalMixin,
  ],

  template:  `<div class="modal-content-wrapper">
                <p>Pick a name for your new project. When creating the project, you will be asked where to save to project to.</p>

                <input
                  type="text"
                  v-model="projectName"
                  placeholder="Project name"
                  v-autofocus
                />

                <div class="modal-buttons">
                  <button @click="$emit('close')" class="secondary">Cancel</button>
                  <button
                    :disabled="validProjectName === false && creatingProject === false"
                    @click="createProject"
                    ref="defaultButton"
                    class="primary"
                  >Create project<template v-if="creatingProject">&nbsp;<loading-spinner/></template></button>
                </div>

                <icon-close @close="$emit('close')"/>
              </div>`,

  data () {
    return {
      projectName: '',
      creatingProject: false, // TODO: Move to store?
    };
  },

  computed: {
    validProjectName () {
      // Check if project name is empty-ish or contains invalid characters
      return FileSystem.isValidEntryName(this.projectName);
    },
  },

  methods: {
    async createProject () {
      if (this.creatingProject === true) return;
      this.creatingProject = true;

      try {
        const projectID = await this.$store.dispatch('projectsManager/createProject', this.projectName);

        // If projectID is undefined, user aborted
        if (projectID !== undefined) {
          this.$router.push({
            name: 'Project loading',
            params: {
              projectID,
            },
          });
        }
        else {
          // Just stop. Don't close the modal or anything.
          this.creatingProject = false;
          return;
        }
      }
      catch (error) {
        this.$modal.show(ModalUnknownError, { error });
      }

      // Close self
      this.creatingProject = false;
      this.$emit('close');
    },
  },
};
