const mouseButtonMap = new Map([['left', 0], ['middle', 1], ['right', 2]]);

export class EventHelper {

  constructor () {}

  static kill (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  // Returns whether one of the following keys was (also) pressed: ALT, SHIFT, CTRL, META (e.g. cmd, windows)
  static hasModifierKeyPressed (event) {
    // Returns true if one of these is true
    return (event.altKey || event.shiftKey || event.ctrlKey || event.metaKey);
  }

  static usedMouseButton (buttonName, event) {
    const buttonID = mouseButtonMap.get(buttonName);
    if (buttonID === undefined) return false;
    else return (('button' in event && event.button !== buttonID) === false);
  }

  // Returns whether the event took place on or inside the given element.
  static happenedOnElementOrDescendant (element, event) {
    if (!(event instanceof Event)) return false;
    if (!(element instanceof Element)) return false;

    // event.target = the element the event happened on
    if (!event.target) return false;
    // TODO: Node.contains() should do the tric already, since it should include the Node the method is called on.
    return (event.target === element || element.contains(event.target) === true);
  }

  // Returns whether the event took place on or inside an element with the given selector.
  static happenedOnSelectorOrHasParentWithSelector (selector, event) {
    if (!(event instanceof Event)) return false;
    if (typeof selector !== 'string') return false;

    // event.target = the element the event happened on
    if (!event.target) return false;
    return (event.target.matches(selector) || event.target.closest(selector) !== null);
  }

  // Returns whether the event took place within given promixity to the given element.
  static happenedNearElement (element, event, proximity = 20 /* px */) {
    if (!(event instanceof Event)) return false;
    if (!(element instanceof Element)) return false;

    // event.target = the element the event happened on
    const {
      target,
      clientX,
      clientY,
    } = event;

    if (!target) return false;

    const { left, right, top, bottom } = element.getBoundingClientRect();

    return !(
      clientX < (left - proximity) ||
      clientX > (right + proximity) ||
      clientY < (top - proximity) ||
      clientY > (bottom + proximity)
    );

  }

};