import cloneDeep from 'lodash-es/cloneDeep.js';

// Partial credits to GitHub user Reinhard (https://stackoverflow.com/users/522201/reinhard)
// https://stackoverflow.com/questions/42878329/going-back-to-states-like-undo-redo-on-vue-js-vuex

// TODO: Save mutations only, not the entire state.

class UndoRedoHistory {

  constructor () {
    this.store = null;
    this.history = [];
    this.currentIndex = -1;
  }

  init (store) {
    this.store = store;
  }

  addState (state) {
    // may be we have to remove redo steps
    if (this.currentIndex + 1 < this.history.length) {
      this.history.splice(this.currentIndex + 1);
    }
    this.history.push(state);
    this.currentIndex++;
  }

  // TODO: add support for undoing up to a certain point in history
  undo () {
    const prevState = this.history[this.currentIndex - 1];
    if (prevState === undefined) {
      console.warn('Nothing to undo');
      return;
    }
    // take a copy of the history state
    // because it would be changed during store mutations
    // what would corrupt the undo-redo-history
    // (same on redo)
    this.store.replaceState(cloneDeep(prevState));
    this.currentIndex--;
  }

  // TODO: add support for redoing up to a certain point in history
  redo () {
    const nextState = this.history[this.currentIndex + 1];
    if (nextState === undefined) {
      console.warn('Nothing to redo');
      return;
    }
    this.store.replaceState(cloneDeep(nextState));
    this.currentIndex++;
  }
}

export const undoRedoHistory = new UndoRedoHistory();