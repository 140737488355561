import Vue from 'vue';

Vue.component('loading-screen', {

  props: {
    loadingText: {
      type: String,
      default: 'Loading',
    },
    spinnerVisible: {
      type: Boolean,
      default: true,
    }
  },

  template:  `<div class="loading-screen">
                <transition
                  name="fade"
                  appear
                >
                  <loading-spinner :visible="spinnerVisible"/>
                </transition>

                <div class="loading-text-wrapper">
                  <transition
                    name="slide-fade"
                    mode="in-out"
                    appear
                  >
                    <p class="loading-text" :key="loadingText">{{ loadingText }}</p>
                  </transition>
                </div>
              </div>`,
});