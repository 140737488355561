import { config } from '../../config.js';
import cloneDeep from 'lodash-es/cloneDeep.js';

// REVIEW: Move all this into 'system' store?
// TODO: Don't import config. Try to always use system store instead. Another reason to merge system store with this one.

const localStorageKey = `${config.configPrefix}appSettings`;

const defaultAppSettings = {
  layout: {
    sidebarVisible: true,
    sidebarWidth: 25, // %
  },
};

function getCurrentOrDefaultAppSettings () {
  const savedAppSettingsJSON = localStorage.getItem(localStorageKey),
        defaultAppSettingsCopy = cloneDeep(defaultAppSettings);

  if (savedAppSettingsJSON) {
    const savedAppSettings = JSON.parse(savedAppSettingsJSON);
    return Object.assign(defaultAppSettingsCopy, savedAppSettings);
  }

  else {
    return defaultAppSettingsCopy;
  }
}

// This function is meant to be used as a reduce function
// 'currentSettingObject' is the level in the 'appSettings' object we're currently looking in.
// In every iteration of this reduce function, we're going one level deeper, until all
// setting path parts have been processed.
function appSettingsTraverser (currentSettingObject, settingPathPart) {
  // If the object we're about to look in is undefined, the setting does not exist and we need to basically stop everything.
  if (typeof currentSettingObject === 'undefined') {
    throw new Error(`Setting does not exist`);
  }

  // One level at a time, look for the current setting path part (for example 'layout') in currentSettingObject
  // If it exists, return the next level to look in, or just the resulting setting.
  if (settingPathPart in currentSettingObject) {
    return currentSettingObject[settingPathPart];
  }
}

export const appSettingsStore = {

  namespaced: true,

  state: {
    appSettings: getCurrentOrDefaultAppSettings(),
  },

  getters: {
    // settingPath:
    // An list of keys that represent the 'path' to get to the desired setting in the 'appSettings' object.
    // Example: getAppSetting('layout', 'sidebarWidth');
    getAppSetting: ({ appSettings }) => (...settingPath) => {
      try {
        const resultingSetting = settingPath.reduce(appSettingsTraverser, appSettings);
        return resultingSetting;
      }

      catch (err) {
        console.error(`Setting '${settingPath.join('.')}' could not be found in appSettings.`);
      }
    },
  },

  mutations: {
    CHANGE_APP_SETTING (state, { settingPath, newValue }) {
      // Get the lowest level setting key, e.g. 'sidebarVisible' when settingPath is ['layout', 'sidebarVisible']
      const lowestLevelSettingKey = settingPath.splice(-1);

      // Setting container is the object containing the lowestLevelSettingKey
      const settingContainer = settingPath.reduce(appSettingsTraverser, state.appSettings);

      // Actually set the new value in this object, which is a reference to the setting in state.appSettings
      // TODO: Error handling
      settingContainer[lowestLevelSettingKey] = newValue;

      // Save back to localStorage
      // TODO: Debounce!
      localStorage.setItem(localStorageKey, JSON.stringify(state.appSettings));
    },
  },

};