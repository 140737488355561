import moment from 'moment';
import { config } from '../config.js';

// TODO: Turn into static class?
export const SystemLogger = new class SystemLogger {

  el = document.querySelector('#system-log');

  log (status, ...devLogs) {
    this.addToDOM(status);
    const isWarning = [status, ...devLogs].some(anotherLog => anotherLog instanceof Error);
    if (config.debug === true) {
      console[isWarning ? 'warn' : 'log'](status, ...devLogs);
    }
  }

  // warn (status, ...devLogs) {
  //   this.addToDOM(status);
  //   if (config.debug === true) {
  //     console.warn(status, ...devLogs);
  //   }
  // }

  addToDOM (status) {
    this.el.innerHTML += `<p>${moment().toISOString()} – ${status}</p>`;
  }

}