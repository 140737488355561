import Vue from 'vue';
import { eventHub } from '../../event-hub.js';

Vue.component('event-teaser', {

  props: [
    'eventDefinition',
  ],

  // TODO: Make browsable with arrow up and down keys

  template:  `<li
                @click="showEventFull(eventDefinition)"
                @contextmenu.prevent.stop="contextMenu"
                tabindex="-1"
              >
                <span>{{ eventDefinition.name }}</span>
              </li>`,

  methods: {
    showEventFull (eventDefinition) {
      this.$store.commit('project/SET_MAIN_VIEW', {
        component: 'event-full',
        bindings: {
          eventID: eventDefinition.name, // NOTE: Temporary
        },
      });
    },

    contextMenu (event) {
      this.$el.focus();

      eventHub.$emit('spawnContextMenu', {
        event,
        options: {
          'group-actions': [
            {
              icon: '✎',
              label: 'Duplicate',
              action: (event) => {
                this.duplicateEvent();
              },
            },
          ],
          'group-special-actions': [
            {
              icon: '✖', // X, ✕, ☓, ✖, ✗, ✘
              label: 'Delete',
              disabled: this.deletable === false,
              action: (event) => {
                this.deleteEvent();
              },
            },
          ],
        },
      });
    },

    duplicateEvent () {
      console.log('Duplicate event', this.eventDefinition);
    },

    deleteEvent () {
      console.log('Delete event', this.eventDefinition);
    },
  },

});