export const MacroContainer = {

  // computed: {
  // },

  methods: {
    // Multiplied/divided by 100 isn't the way to go of course, because it's linear.
    // We need to use some kind of exponential scale, from -Inf dB to +6.
    levelToRange (level) {
      return level * 100;
    },

    // Same here
    rangeToLevel (range) {
      // {{ Math.round(35 * (Math.log(value)/Math.LN10) - 70) + 'dB' }}
      return range / 100;
    },

    // -1 to 1 mapped to -100 to 100
    panToRange (pan) {
      return pan * 100;
    },

    // And the other way around
    rangeToPan (range) {
      return range / 100;
    },


    getPanDisplay (pan) {
      const value = Math.round(pan);
      let valueSuffix = '';

      if (value < 0) valueSuffix = ' L';
      else if (value > 0) valueSuffix = ' R';
      else return 'M';

      return Math.abs(value) + valueSuffix;
    },

    getLevelDisplay (level) {
      // TODO: map 0 to 1 –> -Inf to +6dB (yes, +6!) correctly with an external helper function
      // IDEA: Check link at top of this file (article about range sliders in combination with Web Audio on MDN).
      const dB = Math.round(35 * (Math.log(level)/Math.LN10) - 70);
      return `${ (dB === -Infinity) ? '-inf' : dB } dB`;
    },
  },

};