import Vue from 'vue';

// TODO: Add tooltip support with icon mixin or something
Vue.component('icon-close', {

  props: {
    'title': {
      type: String,
      default: 'Close',
    },
  },

  template:  `<span class="icon-close clickable" :title="title" @click.stop="$emit('close')"></span>`,

});