export const ContextMenuOptionMixin = {

  props: [
    'option',
    'pickingInProgress',
  ],

  methods: {
    // If option was disabled, block click
    optionDisabled () {
      // If option was disabled, block click
      if (this.option.disabled === true) {
        console.warn(`Option '${this.option.label}' is not available.`);
        return true;
      }

      return false;
    },
  },

};