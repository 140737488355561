import Vue from 'vue';
import { mapState } from 'vuex';
import { eventHub } from '../../event-hub.js';
import { events as eventsFixtures } from '../../fixtures/events.js';

Vue.component('event-browser', {

  template:  `<nav
                class="event-browser"
                @contextmenu.prevent.stop="contextMenu"
              >
                <h3 class="title">Events</h3>
                <ul>
                  <template v-for="eventDefinition of registeredEvents">
                    <li
                      is="event-teaser"
                      :eventDefinition="eventDefinition"
                      :class="{ 'is-active': eventIsActiveView(eventDefinition) }"
                      class="option"
                    />
                  </template>
                </ul>

                <input type="text" list="events" class="event-cli" v-model="tempEventCliContent" @keydown.enter="triggerEvent(tempEventCliContent); tempEventCliContent = null"/>
                <datalist id="events">
                  <option v-for="event of registeredEvents" :value="event.name"/>
                </datalist>
              </nav>`,

  data () {
    return {
      // NOTE: Temporary
      registeredEvents: eventsFixtures,

      // NOTE: Temporary
      tempEventCliContent: '',
    };
  },

  computed: {
    ...mapState('project', [
      'mainView',
    ]),
  },

  methods: {
    eventIsActiveView (eventDefinition) {
      return (this.mainView.component === 'event-full' && this.mainView.bindings.eventID === eventDefinition.name);
    },

    triggerEvent (name) {
      console.log(`TRIGGER ${name} now!`);
    },

    contextMenu (event) {
      eventHub.$emit('spawnContextMenu', {
        event,
        options: {
          'group-actions': [
            {
              icon: '✎',
              label: 'New event',
              action: (event) => {
                this.newEvent();
              },
            },
            {
              icon: '📁',
              label: 'New folder',
              action: (event) => {
                this.newFolder();
              },
            },
          ],
        },
      });
    },

    newEvent () {
      console.log('New event');
    },

    newFolder () {
      console.log('New folder');
    },
  },

});