// Most of these are from: https://www.materialui.co/colors
export const colors = [
  // row 1 (yellow to purple)
  'yellow',
  'gold',
  'orange',
  'coral',
  'dark-pink',
  'mulberry',

  // row 2 (green to deep purple)
  ...[
    'fuchsia',
    'indigo',
    'sky-blue',
    'pale-turquoise',
    'sea-green',
    'fern',
  ].reverse(),

  // row 3 (white to brown and black)
  'porcelain',
  'silk',
  'ferra',
  'light-gray',
  'san-juan',
  'oxford-blue',
];