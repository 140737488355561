import Vue from 'vue';
import { eventHub } from '../../event-hub.js';
import { EventHelper } from '../../helpers/EventHelper.js';

// TODO: Rename this to mixer-view, mixer-overview or main-mixer

// IDEA: Flatten tracks in a project. Get rid of the groups. It makes a lot of things harder than they should be.
// Also, maybe convert most of the objects where the keys are GUIDs to arrays. But only if that isn't more memory consuming.
// NOTE: Probably don't do this thing above, we will probably end up having to “collect” tracks from multiple places anyway (Sound Containers, Sends/returns...)

Vue.component('tracks-overview', {

  template:  `<section
                class="tracks-overview"
                @click.left.self="selectTrack(null)"
                @contextmenu.prevent.stop="contextMenu"
              >
                <div class="return-channels tracks-container">
                  <template v-for="(channelDef, channelKey) of project.return_channels">
                    <audio-track
                      :channelDef="channelDef"
                      @selectTrack="selectTrack"
                      :class="getTrackClasses(channelDef)"
                      :deletable="true"
                      :renamable="true"
                      :draggable="true"
                    />
                  </template>
                </div>

                <div class="master-track tracks-container">
                  <audio-track
                    :channelDef="project.master"
                    @selectTrack="selectTrack"
                    :class="getTrackClasses(project.master)"
                    :deletable="false"
                    :renamable="false"
                    :draggable="false"
                  />
                </div>
              </section>`,

  data () {
    return {
      // null or channel object
      selectedTrack: null,
    };
  },

  created () {
    document.addEventListener('keydown', this.keydown, { passive: false, capture: false, });
  },

  beforeDestroy () {
    document.removeEventListener('keydown', this.keydown, { passive: false, capture: false, });
  },

  computed: {
    project () {
      return this.$store.state.projectsManager.activeProjectData;
    },
  },

  methods: {
    getTrackClasses (track) {
      return {
        'selected': this.selectedTrack === track,
        'master': this.project.master === track,
      };
    },

    keydown (event) {
      if (event.defaultPrevented === true) return;
      if (event.repeat === true) return;
      if (EventHelper.hasModifierKeyPressed(event) === true) return;

      switch (event.key) {
        case 'ArrowLeft':
          EventHelper.kill(event);
          this.selectPrevious();
          break;

        case 'ArrowRight':
          EventHelper.kill(event);
          this.selectNext();
          break;
      }
    },

    isSelectedTrack (value) {
      return (value === this.selectedTrack);
    },

    selectTrack (track) {
      this.selectedTrack = track;
    },

    selectPrevious () {
      // TODO: Temporarily only selecting return tracks
      const returnTracks = Object.values(this.project.return_channels);

      if (this.selectedTrack === null) {
        this.selectedTrack = returnTracks[returnTracks.length - 1];
      }

      else {
        const currentlySelectedTrackIndex = returnTracks.findIndex(this.isSelectedTrack);

        // If first, go to the last one
        if (currentlySelectedTrackIndex === 0) {
          this.selectedTrack = returnTracks[returnTracks.length - 1];
        }

        // Else go to the previous track
        else {
          this.selectedTrack = returnTracks[currentlySelectedTrackIndex - 1];
        }
      }
    },

    selectNext () {
      // TODO: Temporarily only selecting return tracks
      const returnTracks = Object.values(this.project.return_channels);

      if (this.selectedTrack === null) {
        this.selectedTrack = returnTracks[0];
      }

      else {
        const currentlySelectedTrackIndex = returnTracks.findIndex(this.isSelectedTrack);

        // If last, go to the first one
        if (currentlySelectedTrackIndex === (returnTracks.length - 1)) {
          this.selectedTrack = returnTracks[0];
        }

        // Else go to the next track
        else {
          this.selectedTrack = returnTracks[currentlySelectedTrackIndex + 1];
        }
      }
    },

    contextMenu (event) {
      eventHub.$emit('spawnContextMenu', {
        event,
        options: {
          'group-actions': [
            {
              icon: '✎',
              label: 'New track',
              action: (event) => {
                this.newTrack();
              },
            },
          ],
        },
      });
    },

    newTrack () {
      console.log('New track');
    },
  },

});