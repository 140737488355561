import { ModalMixin } from '../mixins/ModalMixin.js';

export const ModalUnknownError = {

  name: 'modal-unknown-error',

  mixins: [
    ModalMixin,
  ],

  props: [
    'error',
  ],

  template:  `<div class="modal-content-wrapper">
                <p>An unknown error occurred.</p>
                <details>
                  <summary>Show me the details</summary>
                  <p class="error"><pre>{{ error.stack || error.toString() }}</pre></p>
                  <p class="reach-out">If this appears to be a problem outside of your reach, don't hesitate to <a :href="mailtoString">let me know</a>.</p>
                </details>

                <div class="modal-buttons">
                  <button @click="$emit('close')" ref="defaultButton">{{ getRandomButtonText('disappointed') }}</button>
                </div>

                <icon-close @close="$emit('close')"/>
              </div>`,

  computed: {
    mailtoString () {
      const body = `Hey there,\n\nI came across an unknown error. Could you help me out? This is the error I got:\n\n“${this.error}”\n\nThanks`;
      return `mailto:${this.mailHelp}?body=${encodeURIComponent(body)}`;
    },
  },

};
