import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

// TODO: Add tabs or icons to switch between sidebar views (event browser, asset browser etc.)
Vue.component('region-sidebar', {

  template:  `<aside
                class="sidebar"
              >
                <component
                  class="sidebar-main-view"
                  :is="sidebarView.component"
                  v-bind="sidebarView.bindings"
                />
                <!-- <event-browser/> -->
                <!-- <asset-browser/> -->
                <resize-grabber
                  resizeType="x"
                  :min="0"
                  :max="60"
                  :value.sync="sidebarWidth"
                />
              </aside>`,

  computed: {
    ...mapState('project', [
      'sidebarView',
    ]),

    ...mapGetters('settings', [
      'getAppSetting',
    ]),

    sidebarWidth: {
      get () {
        return this.getAppSetting('layout', 'sidebarWidth');
      },

      set (newValue) {
        this.$store.commit('settings/CHANGE_APP_SETTING', {
          settingPath: ['layout', 'sidebarWidth'],
          newValue,
        });
      },
    },
  },

});