import Vue from 'vue';
import moment from 'moment';
import { EspressivoProjectHelper } from '../../system/EspressivoProjectHelper.js';
import { ENTRY_MISSING } from '../../system/FileSystem.js';
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';

Vue.component('project-teaser', {

  props: {
    projectID: {
      type: String,
      required: true,
    },
  },

  template:  `<li
                @click="isMissing ? fixProject() : loadProject()"
                class="option project teaser"
                :class="{
                  'is-missing': isMissing,
                }"
              >
                <h3 class="project-title">{{ projectName }}</h3>

                <template v-if="isMissing === true">
                  <p>
                    <small>
                      <span><strong>Folder missing.</strong> Perhaps you moved or renamed this folder?</span>
                    </small>
                  </p>
                </template>
                <template v-else-if="isLocked === true">
                  <p>
                    <small>
                      <span><strong>Project locked. Click to show browser’s read/write permission prompt.</strong></span>
                    </small>
                  </p>
                </template>
                <template v-else>
                  <!-- FIXME: Figure out how to do this. 'last_modified' and 'created' are saved into the projectData, but do we load each project file just to display it here? We could instead read the 'last_modified' from File System Access directory handle as well... -->
                  <!-- <p>
                    <small>
                      <span>Last modified&nbsp;</span>
                      <time
                        :datetime="projectData.last_modified"
                        :title="generateReadableDatetime(projectData.last_modified)"
                      >{{ generateTimestring(projectData.last_modified) }}</time>
                    </small>
                  </p>

                  <p>
                    <small>
                      <span>Created&nbsp;</span>
                      <time
                        :datetime="projectData.meta.created"
                        :title="generateReadableDatetime(projectData.meta.created)"
                      >{{ generateTimestring(projectData.meta.created) }}</time>
                    </small>
                  </p> -->

                  <p>
                    <small>
                      <span>{{ versionsCount }} {{ (versionsCount > 1) ? 'versions' : 'version' }}</span>
                    </small>
                  </p>
                </template>

                <icon-close @close="removeProject" title="Remove project from list"/>
              </li>`,

  computed: {
    ...mapState('projectsManager', [
      'availableProjects',
    ]),

    ...mapGetters('projectsManager', [
      'projectAvailable',
      'projectReadWritable',
    ]),

    projectName () {
      return this.projectID;
    },

    project () {
      return this.availableProjects[this.projectID];
    },

    versionsCount () {
      const { project, projectDirectoryReadWritable } = this;

      if (projectDirectoryReadWritable === true) {
        return EspressivoProjectHelper.getProjectFileTreeStructureItems(project.tree).length;
      }
      else {
        return null;
      }
    },

    projectDirectoryReadWritable () {
      return this.projectReadWritable(this.projectID);
    },

    isMissing () {
      return this.projectAvailable(this.projectID) === false;
    },

    isLocked () {
      return this.projectDirectoryReadWritable === false;
    },
  },

  methods: {
    loadProject () {
      this.$router.push({
        name: 'Project loading',
        params: {
          projectID: this.projectID,
        },
        query: {
          unlock: true,
        },
      });
    },

    removeProject (projectID) {
      // FIXME: Finish this
      confirm('Remove project from dashboard? This will not remove the project from your device.');
    },

    fixProject () {
      alert(`Don't worry, you probably just moved or renamed this folder. For now, just use the Open project button. In a while, you'll be able to ‘fix’ your project from here.`);
    },

    generateTimestring (ISOTimestamp) {
      if (!ISOTimestamp) {
        return console.error('Timestamp missing');
      }

      const now = moment(),
            then = moment(ISOTimestamp),
            difference = moment.duration(then.diff(now));
      return difference.humanize(true);
    },

    generateReadableDatetime (ISOTimestamp) {
      return moment(ISOTimestamp).format('LLL');
    },
  },

});