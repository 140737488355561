import moment from 'moment';
import { config } from '../config.js';
import { SystemLogger } from '../system/SystemLogger.js';

export function getDefaultEspressivoProjectFile () {
  SystemLogger.log('Creating new default project file');

  return JSON.stringify({
    "meta": {
      "espressivo_version": config.appVersion,
      "created": moment().toISOString(),
      "last_modified": moment().toISOString(),
    },
    "oneshots": {},
    "return_channels": {},
    "master": {
      "name": "Master",
      "color": "san-juan",
      "level": 1,
      "pan": 0,
      "inserts": [],
    },
    "plugins": [],
  });
};