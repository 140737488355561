import { ModalMixin } from '../mixins/ModalMixin.js';

export const ModalProjectMissing = {

  name: 'modal-project-missing',

  mixins: [
    ModalMixin,
  ],

  props: [
    'error',
  ],

  template:  `<div class="modal-content-wrapper">
                <p>It seems like you've never opened project “<strong>{{ $route.params.projectID }}”</strong> before. We keep track of the projects you opened earlier, but sometimes things get lost, like when you clear your browsing data. To open the project, go back to the Dashboard and simply locate the project folder again.</p>
                <details>
                  <summary>Show me the details</summary>
                  <p class="error"><pre>{{ error.stack || error.toString() }}</pre></p>
                  <p class="reach-out">If this appears to be a problem outside of your reach, don't hesitate to <a :href="mailtoString">let me know</a>.</p>
                </details>

                <div class="modal-buttons">
                  <button @click="$emit('close')" ref="defaultButton">{{ getRandomButtonText('disappointed') }}</button>
                </div>

                <icon-close @close="$emit('close')"/>
              </div>`,

  computed: {
    mailtoString () {
      const body = `Hey there,\n\nI tried to open a project with the ID “${this.$route.params.projectID}” but it told me the project doesn't exist. This is the error I got:\n\n“${this.error}”\n\nThanks`;
      return `mailto:${this.mailHelp}?body=${encodeURIComponent(body)}`;
    },
  },

};
