import cloneDeep from 'lodash-es/cloneDeep.js';

export const ResettableComponentMixin = {

  // NOTE: In components with this mixin, add initialComponentState to your component, which is what you'd normally return in the 'data' function.
  // initialComponentState: {},

  data () {
    return this.getInitialComponentState();
  },

  methods: {
    getInitialComponentState () {
      return cloneDeep(this.$options.initialComponentState);
    },

    resetComponentState () {
      Object.assign(this, this.getInitialComponentState());
    },
  },

};