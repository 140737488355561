import Vue from 'vue';

Vue.component('loading-spinner', {

  props: {
    'visible': {
      type: Boolean,
      default: true,
    },
  },

  template:  `<div
                class="spinner"
                :class="{ 'visible': visible, 'hidden': !visible }"
              ></div>`,

});