import { IDBStorage } from '../../system/IDBStorage.js';
import { EspressivoProjectHelper } from '../../system/EspressivoProjectHelper.js';
import { SystemLogger } from '../../system/SystemLogger.js';
import {
  FileSystem,
  ENTRY_ACCESSIBLE,
  ENTRY_MISSING,
  ENTRY_FAILED_TO_LOAD,
} from '../../system/FileSystem.js';
import { FileSystemTree } from '../../system/FileSystemTree.js';
import moment from 'moment';
import cloneDeep from 'lodash-es/cloneDeep.js';


function createAvailableProjectsEntry (handle) {
  return {
    state: null,
    handle,
    tree: null,
  };
}

// A store that manages all projects (create project, delete project, rename project...)

export const projectsManagerStore = {

  namespaced: true,

  state: {
    availableProjects: {},
    initiallyGettingAvailableProjects: true, // always getting projects immediately on pageload, so to avoid flashes the default is true
    gettingAvailableProjects: false,
    loadingProjectID: null,
    savingProjectID: null,
    activeProjectID: null, // name of the project directory
    activeProjectDirectoryTreeStructure: null, // most recent representation of project directory tree structure
    activeProjectData: null, // data in the .espressivo file
  },

  getters: {
    currentLoadingState (state) {
      const {
        gettingAvailableProjects,
        loadingProjectID,
        savingProjectID,
      } = state;

      if (gettingAvailableProjects === true) {
        return 'Listing projects';
      }

      else if (loadingProjectID !== null && loadingProjectID !== undefined) {
        return `Loading project ${loadingProjectID}`;
      }

      else if (savingProjectID !== null && savingProjectID !== undefined) {
        return `Saving project ${savingProjectID}`;
      }

      else {
        return null;
      }
    },

    projectAvailable: state => (projectID) => {
      return (projectID in state.availableProjects);
    },

    projectReadWritable: (state, getters) => (projectID) => {
      if (getters.projectAvailable(projectID) === false) {
        return false;
      }

      const {
        state: permissionState,
        tree,
      } = state.availableProjects[projectID];

      return permissionState === ENTRY_ACCESSIBLE && tree instanceof FileSystemTree;
    },
  },

  mutations: {
    STORE_AVAILABLE_PROJECTS (state, availableProjects) {
      Object.assign(state.availableProjects, availableProjects);
    },

    STORE_PROJECT (state, { projectID, projectDirectoryTreeStructure }) {
      // FIXME: Reactivity seems to fail when user opens same project twice and data inside the project has changed. Use Vue.set instead and/or check why new data is not properly saved in the store.
      state.availableProjects[projectID] = {
        // NOTE: We would not have gotten a tree if the entry was not read-/writable.
        state: ENTRY_ACCESSIBLE,
        handle: projectDirectoryTreeStructure.getHandle(),
        tree: projectDirectoryTreeStructure,
      };
    },

    SET_PROJECT_PERMISSION_STATE (state, { projectID, permissionState, tree }) {
      state.availableProjects[projectID].state = permissionState;

      /**
       * NOTE:
       * If the new permission state is ENTRY_ACCESSIBLE, then the callee
       * is responsible for sending along a freshly created tree structure.
       */
      if (tree != null) {
        state.availableProjects[projectID].tree = tree;
      }
    },

    SET_GETTING_AVAILABLE_PROJECTS_STATE (state, bool) {
      state.gettingAvailableProjects = bool;
    },

    SET_INITIALLY_GETTING_AVAILABLE_PROJECTS_STATE (state, bool) {
      state.initiallyGettingAvailableProjects = bool;
    },

    SET_LOADING_PROJECT_ID (state, projectID) {
      if (projectID !== null && projectID !== undefined) {
        SystemLogger.log(`Now loading project '${projectID}'`);
      }

      // REVIEW: Shouldn't if statement above also encapsulate this?
      state.loadingProjectID = projectID;
    },

    SET_SAVING_PROJECT_ID (state, projectID) {
      if (projectID !== null && projectID !== undefined) {
        SystemLogger.log(`Now saving project '${projectID}'`);
      }

      // REVIEW: Shouldn't if statement above also encapsulate this?
      state.savingProjectID = projectID;
    },

    SET_ACTIVE_PROJECT_ID (state, projectID) {
      SystemLogger.log(`Active project is now '${projectID}'`);
      state.activeProjectID = projectID;
    },

    SET_ACTIVE_PROJECT_DATA (state, projectFileContents) {
      if (EspressivoProjectHelper.validateProjectData(projectFileContents)) {
        state.activeProjectData = projectFileContents;
      }
      else {
        throw new Error('Malformed project data');
      }
    },

    // REVIEW: Why do this? Can't this be done with a getter using availableProjects and activeProjectID?
    SET_ACTIVE_PROJECT_DIRECTORY_TREE_STRUCTURE (state, projectDirectoryTreeStructure) {
      state.activeProjectDirectoryTreeStructure = projectDirectoryTreeStructure;
    },
  },

  actions: {
    async initiallyGetAvailableProjects ({ state, commit, dispatch }) {
      await dispatch('getAvailableProjects');
      commit('SET_INITIALLY_GETTING_AVAILABLE_PROJECTS_STATE', false);
    },

    async getAvailableProjects ({ state, commit }) {
      if (state.gettingAvailableProjects === true) {
        SystemLogger.log('Already getting projects, skipping duplicate call');
        return;
      }

      // Make sure components know that a list of all available projects is being fetched.
      commit('SET_GETTING_AVAILABLE_PROJECTS_STATE', true);

      // Do the fetch.
      // TODO: Error handling
      try {
        const result = await IDBStorage.getAvailableProjects();

        /**
         * Prepare an `availableProjects` object:
         * Turn into { id: { tree, handle, state } } pairs, where:
         * - `id` is the name of the directory
         * - `state` is a symbol that indicates the read/write state of this directory,
         * - `handle` is the FileSystemDirectoryHandle
         * - `tree` is the tree structure (i.e. the `FileSystemTree` instance) of
         *    this directory, if we already have permission to read/write to
         *    the directory.
         */
        const availableProjects = {};

        for (const projectDirHandle of result) {
          const projectDirName = projectDirHandle.name;
          const permissionState = await FileSystem.queryPermission(projectDirHandle);

          const availableProjectsEntry = createAvailableProjectsEntry(projectDirHandle);

          if (permissionState === ENTRY_ACCESSIBLE) {
            try {
              availableProjectsEntry.state = ENTRY_ACCESSIBLE;
              const tree = await FileSystem.getTreeStructure(projectDirHandle);
              availableProjectsEntry.tree = tree;
            }
            catch (err) {
              // Entry missing
              if (err instanceof DOMException && err.name === 'NotFoundError') {
                availableProjectsEntry.state = ENTRY_MISSING;
              }
              // Something else is wrong
              else {
                availableProjectsEntry.state = ENTRY_FAILED_TO_LOAD;
                SystemLogger.log(`Failed to load project`, {
                  err,
                  projectDirName,
                  projectDirHandle,
                  permissionState,
                });
              }
            }
          }
          else {
            availableProjectsEntry.state = permissionState;
          }

          availableProjects[projectDirName] = availableProjectsEntry;
        }

        // Store results.
        commit('STORE_AVAILABLE_PROJECTS', availableProjects);
      }
      catch (err) {
        SystemLogger.log('Something went wrong while getting projects', err);
      }

      // Reset state.
      commit('SET_GETTING_AVAILABLE_PROJECTS_STATE', false);
    },

    async requestProjectDirectoryPermission ({ state: { availableProjects }, getters, commit }, projectID) {
      // If project not in available projects list, abort.
      if (getters.projectAvailable(projectID) === false) {
        throw new Error(`Can't request permission to read/write in project with ID '${projectID}', because no project with that ID has been opened before.`);
      }

      // Show 'request permission' popup.
      // When you deny this permission request, isWritable will be 'prompt' once more.
      // TODO: Factcheck statements above.
      const project = availableProjects[projectID],
            projectDirHandle = project.handle;

      const permissionState = await FileSystem.requestPermission(projectDirHandle);

      let tree = null;
      if (permissionState === ENTRY_ACCESSIBLE) {
        tree = await FileSystem.getTreeStructure(projectDirHandle);
      }

      commit('SET_PROJECT_PERMISSION_STATE', {
        projectID,
        permissionState,
        tree,
      });
    },

    // NOTE: Components that want to set the active project, need to check if the directory handle is accessible.
    async setActiveProject ({ state: { loadingProjectID, activeProjectID, availableProjects }, getters, commit }, projectID) {
      // If a project is already being fetched, abort.
      if (loadingProjectID !== null && loadingProjectID !== undefined) {
        throw new Error(`Can't set active project while another project is loading.`);
      }

      // If the requested project is already active, abort.
      if (projectID === activeProjectID) {
        SystemLogger.log(`Project is already active, no need to set it again.`);
        return;
      }

      // If project not in available projects list, abort.
      if (getters.projectAvailable(projectID) === false) {
        throw new Error(`Can't set project with ID '${projectID}' as the active project, because no project with that ID has been opened before.`);
      }

      // Make sure components know that a project is being fetched.
      commit('SET_LOADING_PROJECT_ID', projectID);

      const projectDirectoryTreeStructure = availableProjects[projectID].tree;

      try {
        // FIXME: In the future, show a version picker
        const {
          treeStructureItem: latestProjectFileTreeStructureItem,
          file: latestProjectFile,
        } = await EspressivoProjectHelper.getMostRecentProjectFileTreeStructureItem(projectDirectoryTreeStructure);

        const projectFileContents = await FileSystem.getFileTreeStructureItemContents(latestProjectFileTreeStructureItem, latestProjectFile);

        // Success! Store the project data.
        commit('SET_ACTIVE_PROJECT_DIRECTORY_TREE_STRUCTURE', projectDirectoryTreeStructure);
        commit('SET_ACTIVE_PROJECT_DATA', projectFileContents);
        commit('SET_ACTIVE_PROJECT_ID', projectID);
      }
      catch (err) {
        // Return error anyway, so the function that triggered this action can do
        // its own error handling.
        throw err;
      }
      finally {
        // Reset state.
        commit('SET_LOADING_PROJECT_ID', null);
      }
    },

    // Returns projectID if successfully created new Espressivo project
    async createProject ({ commit }, projectName) {
      const projectDirectoryTreeStructure = await EspressivoProjectHelper.createNewEspressivoProject(projectName);

      if (projectDirectoryTreeStructure) {
        const projectID = projectDirectoryTreeStructure.entryName;

        commit('STORE_PROJECT', {
          projectID,
          projectDirectoryTreeStructure,
        });

        return projectID;
      }
    },

    // Returns projectID if successfully opened valid Espressivo project
    async openProject ({ commit }) {
      const projectDirectoryTreeStructure = await EspressivoProjectHelper.openExistingEspressivoProject();

      // If 'projectDirectoryTreeStructure' is not set, user aborted.
      if (projectDirectoryTreeStructure) {
        const projectID = projectDirectoryTreeStructure.entryName;

        commit('STORE_PROJECT', {
          projectID,
          projectDirectoryTreeStructure,
        });

        return projectID;
      }
    },

    async saveProject ({ state, commit }) {
      commit('SET_SAVING_PROJECT_ID', state.activeProjectID);
      const projectDirectoryTreeStructure = state.availableProjects[state.activeProjectID];

      const {
        treeStructureItem: {
          entry: latestProjectFileEntry,
        },
      } = await EspressivoProjectHelper.getMostRecentProjectFileTreeStructureItem(projectDirectoryTreeStructure);

      const activeProjectDataClone = cloneDeep(state.activeProjectData);
      activeProjectDataClone.meta.last_modified = moment().toISOString();

      // Check new data before saving.
      if (EspressivoProjectHelper.validateProjectData(activeProjectDataClone) === false) {
        commit('SET_SAVING_PROJECT_ID', null);
        throw new Error('Project data malformed just before saving');
      }

      const newFileContents = JSON.stringify(activeProjectDataClone);

      // Save the project file.
      await EspressivoProjectHelper.saveProjectFile(latestProjectFileEntry, newFileContents);

      commit('SET_SAVING_PROJECT_ID', null);
    },
  },

};