// Types:
/**
 * Types:
 * - folder
 * - event
 *
 * We use the term folder, not 'directory', because:
 * “If one is referring to a container of documents, the term folder is more appropriate.
 *  The term directory refers to the way a structured list of document files and folders is stored on the computer.”
 */
export const events = [
  {
    id: 'weaponry',
    name: 'Weaponry',
    type: 'folder',
    children: [
      {
        id: 'weapon_hits',
        name: 'Weapon hits',
        type: 'folder',
        children: [],
      },
    ],
  },
  {
    id: 'character_sounds',
    name: 'Character sounds',
    type: 'folder',
    children: [
      {
        id: 'footstep',
        name: 'Footstep',
        response: [
          {
            type: 'playSound', // NOTE: types still have to be defined
            id: 'whatever', // name of the switch chosen by the composer/sound designer
            value: 'temporary',
          },
        ],
      },
    ],
  },
  {
    id: 'man_enters_room',
    name: 'ManEntersRoom',
    type: 'event',
    response: [
      {
        type: 'setSwitch',  // NOTE: types still have to be defined
        id: 'surface-type', // name of the switch chosen by the composer/sound designer
        value: 'grass',
      },
    ],
  },
];