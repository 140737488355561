import Vue from 'vue';
import { eventHub } from '../../event-hub.js';
import { MacroContainer } from '../../mixins/MacroContainer.js';

// TODO: Check this for controlling gains with range inputs:
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Controlling_multiple_parameters_with_ConstantSourceNode

// TODO: Naming change: 'Balance' instead of 'Pan'. Option to convert to true stereo panner.

Vue.component('audio-track', {

  debug: true,
  debugTag: 'AudioTrack',
  debugColor: '#5C6BC0',

  props: [
    'channelDef',
    'deletable',
    'renamable',
    'draggable',
  ],

  mixins: [
    MacroContainer,
  ],

  // @click.self because we only want this track to be selected when you truly clicked this track. So not when you click the fader or something.
  // NOTE: We had this before too, but a tooltip might be better –> :title="name"
  // REVIEW: prepareHistory and addToHistory stuff seems deprecated
  template:  `<article
                class="audio-track"
                :class="{ 'folded': folded === true }"
                :style="{ '--track-color': 'var(--color--' + channelDef.color + ')' }"
                @contextmenu.prevent.stop="contextMenu"
                @click="selectSelf"
                @selectstart.prevent
                @dblclick.prevent="foldOrUnfoldTrack"
              >
                <!-- :draggable="draggable !== undefined ? draggable : true" -> but this messes with dragging the fader or panner -->
                <strong class="audio-track-title">{{ name }}</strong>

                <section class="plugins">
                </section>

                <section class="channel-strip">
                  <div class="panner">
                    <span>Pan</span>
                    <ui-macro
                      mode="aroundCenter"
                      :value.sync="pan"
                      :defaultValue="0"
                      :width="55"
                      :mainColor="$colors.secondary"
                      :frameColor="$colors.greyDarker"
                      @dragStart="prepareHistory('pan')"
                      @release="addToHistory('pan')"
                    />
                    <span class="value-display">{{ getPanDisplay(pan) }}</span>
                  </div>

                  <div class="fader">
                    <span>Level</span>
                    <ui-macro
                      mode="linear"
                      :value.sync="level"
                      :defaultValue="90"
                      :width="55"
                      :mainColor="$colors.secondary"
                      :frameColor="$colors.greyDarker"
                      @dragStart="prepareHistory('level')"
                      @release="addToHistory('level')"
                    />
                    <span class="value-display">{{ getLevelDisplay(level) }}</span>
                  </div>
                </section>
              </article>`,

  data () {
    return {
      folded: false,
    };
  },

  computed: {
    guid () {
      return this.channelDef.guid;
    },

    name () {
      return this.channelDef.name;
    },

    level: {
      get () {
        return this.levelToRange(this.channelDef.level);
      },

      set (newValue) {
        this.$store.commit('project/LEVEL_CHANGE', {
          channel: this.channelDef,
          newValue: this.rangeToLevel(newValue),
        });
      }
    },

    pan: {
      get () {
        return this.panToRange(this.channelDef.pan);
      },

      set (newValue) {
        this.$store.commit('project/PAN_CHANGE', {
          channel: this.channelDef,
          newValue: this.rangeToPan(newValue),
        });
      }
    },

    // panDisplay () {
    //   const value = Math.round(this.pan);
    //   let valueSuffix = '';

    //   if (value < 0) valueSuffix = ' L';
    //   else if (value > 0) valueSuffix = ' R';
    //   else return 'M';

    //   return Math.abs(value) + valueSuffix;
    // },

    // levelDisplay () {
    //   // TODO: map 0 to 1 –> -Inf to +6dB (yes, +6!) correctly with an external helper function
    //   // IDEA: Check link at top of this file (article about range sliders in combination with Web Audio on MDN).
    //   const dB = Math.round(35 * (Math.log(this.level)/Math.LN10) - 70);
    //   return `${ (dB === -Infinity) ? '-inf' : dB } dB`;
    // },
  },

  methods: {
    // // Multiplied/divided by 100 isn't the way to go of course, because it's linear.
    // // We need to use some kind of exponential scale, from -Inf dB to +6.
    // levelToRange (level) {
    //   return level * 100;
    // },

    // // Same here
    // rangeToLevel (range) {
    //   // {{ Math.round(35 * (Math.log(value)/Math.LN10) - 70) + 'dB' }}
    //   return range / 100;
    // },

    // // -1 to 1 mapped to -100 to 100
    // panToRange (pan) {
    //   return pan * 100;
    // },

    // // And the other way around
    // rangeToPan (range) {
    //   return range / 100;
    // },

    uniqueIDFor (targetProperty) {
      return `${targetProperty}_${this.guid}`;
    },

    selectSelf (e) {
      this.$emit('selectTrack', this.channelDef);
    },

    contextMenu (event) {
      this.$log('Spawning context menu from AudioTrack');

      eventHub.$emit('spawnContextMenu', {
        event,
        options: {
          'group-actions': [
            {
              icon: '↔',
              label: this.folded === true ? 'Unfold' : 'Fold',
              action: (event) => {
                this.foldOrUnfoldTrack();
              },
            },
            {
              icon: '𝙸',
              label: 'Rename',
              disabled: this.renamable === false,
              action: (event) => {
                this.renameTrack();
              },
            },
            {
              // icon: '⎁',
              icon: '✎',
              label: 'Add description',
              action: (event) => {
                this.addDescriptionToTrack();
              },
            },
          ],
          'group-special-actions': [
            {
              icon: '✖', // X, ✕, ☓, ✖, ✗, ✘
              label: 'Delete',
              disabled: this.deletable === false,
              action: (event) => {
                this.deleteTrack();
              },
            },
          ],
          'group-color': [
            {
              label: 'Color',
              component: 'context-menu-color-picker',
              componentBindings: { activeColor: this.channelDef.color },
              action: (event, color) => {
                this.setTrackColor(color);
              },
            },
          ],
        },
      });
    },

    foldOrUnfoldTrack () {
      this.folded = !this.folded;
    },

    deleteTrack () {
      // TODO: Finish
      console.log('Delete track:', this.channelDef);
      this.$store.commit('project/DELETE_TRACK', this.channelDef);
    },

    renameTrack () {
      // TODO: Finish
      this.$store.commit('project/RENAME_TRACK', {
        channel: this.channelDef,
        newValue: 'arghblargh',
      });
    },

    addDescriptionToTrack () {
      console.log('Add description to track:', this.channelDef);
    },

    setTrackColor (color) {
      this.$store.commit('project/CHANGE_TRACK_COLOR', {
        channel: this.channelDef,
        newValue: color,
      });
    },
  },

});