import Vue from 'vue';
import VueRouter from 'vue-router';

import { Dashboard } from './routes/Dashboard.js';
import { ProjectLoading } from './routes/ProjectLoading.js';
import { Project } from './routes/Project.js';
import { PageNotFound } from './routes/PageNotFound.js';

import { store } from './store.js';

Vue.use(VueRouter);

// Later on, if we need nested routes, try this: https://router.vuejs.org/guide/essentials/nested-routes.html

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Dashboard',
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      pageTitle: 'Dashboard',
    },
  },
  {
    path: '/project',
    redirect: {
      name: 'Dashboard',
    },
  },
  {
    path: '/project/:projectID/loading',
    name: 'Project loading',
    component: ProjectLoading,
    meta: {
      pageTitle: 'Loading',
    },
  },
  {
    path: '/project/:projectID',
    name: 'Project',
    component: Project,
    meta: {
      pageTitle: to => to.params.projectID,
    },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component () {
  //     return import(/* webpackChunkName: "project" */ './routes/About.js').then(module => module.About);
  //   },
  //   meta: {
  //     pageTitle: 'Espressivo Studio',
  //   }
  // },
  {
    path: '*',
    name: 'Page not found',
    component: PageNotFound,
    meta: {
      pageTitle: 'Hmm...',
    },
  },
];

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // If browser isn't supported, stop all navigation.
  if (store.getters['system/supportedBrowser'] === false) {
    next(false);
    return;
  }

  // document.body.dataset.route = to.name;

  const { appName, debug } = store.state.system;

  if (to.meta && to.meta.pageTitle) {
    const pageTitle = (typeof to.meta.pageTitle === 'function') ? to.meta.pageTitle(to) : to.meta.pageTitle;
    document.title = `${pageTitle} | ${appName}`;
  }
  else {
    document.title = appName;
  }

	if (debug === true) {
		console.log(`%c→ ${to.path}`, 'background-color: #dee5ec; color: LightSlateGrey; padding: 2px 6px; border-radius: 3px;');
	}

	next();
});