import { config } from '../config.js';
import { SystemLogger } from './SystemLogger.js';

export const IDBStorage = new class IDBStorage {

  hasIndexedDB = ('indexedDB' in window);

  DBName = config.appName;
  DBVersion = 1;
  projectsObjectStoreName = `${config.appName} projects`;

  // If you need to access all object stores in the database, you can use the property IDBDatabase.objectStoreNames:
  // const transaction = this.DB.transaction(db.objectStoreNames);

  constructor () {
    // IDBStorage.DB needs to be 'awaitable'
    this.DB = this.initIDB()
      .then((database) => {
        // Attach generic error handler for all errors targeted at this database's requests!
        database.onerror = (event) => {
          const errorMsg = event?.target?.errorCode ?? event?.target?.error ?? event;
          console.error(`Database error:`, errorMsg);
        };
        return database;
      });
  }

  async initIDB () {
    SystemLogger.log('Setting up IndexedDB...');

    // NOTE: If needed, uncomment for clean start. If this is commented, onupgradeneeded callback will only run the very first time you're running this code.
    // if (config.debug === true) {
    //   try {
    //     SystemLogger.log(`Trying to delete existing IndexedDB database`);

    //     const DBDeleteRequest = await indexedDB.deleteDatabase(this.DBName);

    //     DBDeleteRequest.onerror = (event) => {
    //       const msg = `An error occured while trying to delete existing IndexedDB database`;
    //       SystemLogger.log(msg, event);
    //       throw new Error(msg);
    //     }

    //     // NOTE: When you have multiple connections to an existing database, the deleteDatabase request may block.
    //     //       So when you're working with multiple tabs open and you get this error, close a tab and reload.
    //     //       See: https://developer.mozilla.org/en-US/docs/Web/API/IDBOpenDBRequest/onblocked
    //     DBDeleteRequest.onblocked = (event) => {
    //       SystemLogger.log(`IndexedDB delete request was blocked`, event);
    //       throw new Error(`IndexedDB delete request was blocked. Make sure to only have this app opened in one tab/window${config.debug === true ? ' while in development mode.' : '.'}`);
    //     };

    //     DBDeleteRequest.onsuccess = (event) => {
    //       SystemLogger.log('Successfully deleted IndexedDB database', event);
    //     };
    //   }
    //   catch (err) {
    //     console.error(err);
    //   }
    // }

    // Open a db instance to save dir/file references for later sessions
    return new Promise((resolve, reject) => {
      const DBOpenRequest = indexedDB.open(this.DBName, this.DBVersion);

      // This event will always run in this case, because we always delete the DB on pageload!
      // Will always run before onsuccess. If this function errors, onerror will be called instead of onsuccess.
      DBOpenRequest.onupgradeneeded = (event) => {
        if (event.oldVersion === 0) {
          SystemLogger.log('Created new IndexedDB database', event);
          SystemLogger.log(`Creating new objectStore '${this.projectsObjectStoreName}'`, event);

          // Create an objectStore for this database
          // We'll use folder names as keys for now
          // TODO: Decide what to use as keys
          DBOpenRequest.result.createObjectStore(this.projectsObjectStoreName, {});
        }
        else {
          SystemLogger.log(`Updating database from version ${event.oldVersion} to ${event.newVersion}`, event);
        }
      };

      DBOpenRequest.onerror = (event) => {
        SystemLogger.log(`Couldn't load IndexedDB database`, event);
        reject(DBOpenRequest.error);
      };

      DBOpenRequest.onblocked = (event) => {
        SystemLogger.log(`IndexedDB open database request was blocked`, event);
      };

      // NOTE: If page seems stuck and 'onsuccess' doesn't get called for some weird reason, read this: https://stackoverflow.com/questions/35276061/indexeddb-open-callbacks-not-called-on-chrome/35278736
      DBOpenRequest.onsuccess = (event) => {
        SystemLogger.log('Successfully opened IndexedDB database');
        resolve(DBOpenRequest.result);
      };
    });
  }

  async getAvailableProjects () {
    const DB = await this.DB;

    return new Promise((resolve, reject) => {
      const transaction = DB.transaction([this.projectsObjectStoreName], 'readonly');

      const objectStore = transaction.objectStore(this.projectsObjectStoreName);
      const objectStoreRequest = objectStore.getAll();

      objectStoreRequest.onsuccess = async (event) => {
        const { result } = objectStoreRequest;
        SystemLogger.log(`Retrieved %c%i%c available projects from IndexedDB`, 'font-weight: bold', result.length, 'font-weight:normal');
        resolve(result);
      }

      objectStoreRequest.onerror = (event) => {
        SystemLogger.log(`Somehow can't get the available projects from IndexedDB`, event);
        reject(objectStoreRequest.error);
      }
    });
  }

  async storeProjectDirectoryHandle (dir_ref) {
    SystemLogger.log('Attempting to store project directory handle');
    const DB = await this.DB;

    if (!(dir_ref instanceof FileSystemDirectoryHandle)) {
      return new TypeError('Should be instance of FileSystemDirectoryHandle');
    }

    await new Promise((resolve, reject) => {
      // Save the reference to the project folder to open the file later.
      const transaction = DB.transaction([this.projectsObjectStoreName], 'readwrite');

      try {
        const objectStore = transaction.objectStore(this.projectsObjectStoreName);
        const objectStoreRequest = objectStore.put(dir_ref, dir_ref.name);

        objectStoreRequest.onsuccess = (event) => {
          SystemLogger.log('Nice, saving the handle to IndexedDB seems to have worked! 🎉', event);
          resolve();
        }

        objectStoreRequest.onerror = (event) => {
          SystemLogger.log(`Somehow can't save to IndexedDB`, event);
          reject(objectStoreRequest.error);
        }

        transaction.commit(); // NOTE: Only if you need to the DB to update ASAP!
      }
      catch (err) {
        SystemLogger.log('Saving handles to IndexedDB is not working yet (or maybe the spec changed: https://github.com/WICG/native-file-system)', err);
        reject(err);
      }
    });
  }

}
