/**
 * `FileSystemTree` is a small wrapper around File System Access API
 * file and directory handles. It's used by `FileSystem.getTreeStructure`
 * to make it easier to traverse an entire directory, by recursively getting
 * all of its contents and storing its file/directory handles in the parent
 * `FileSystemTree`.
 */
export class FileSystemTree {

  #entryHandle = null;

  constructor (entryHandle) {
    if (!(entryHandle instanceof FileSystemHandle)) {
      throw new TypeError('Parameter entryHandle should be an instance of FileSystemHandle');
    }

    this.#entryHandle = entryHandle;

    if (this.entryType === 'directory') {
      this.children = {};
    }
  }

  get entryName () {
    return this.#entryHandle.name;
  }

  get entryType () {
    return this.#entryHandle.kind;
  }

  /**
   * NOTE: Do not use this function unless you really need to get
   * access to the handle directly.
   *
   * @returns {FileSystemHandle}
   */
  getHandle () {
    return this.#entryHandle;
  }

  /**
   * @param {FileSystemTree} childTree
   */
  addChild (childTree) {
    if (this.entryType !== 'directory') {
      throw new TypeError(`Can't add a child if this entry is not a directory`);
    }

    if (!(childTree instanceof FileSystemTree)) {
      throw new TypeError('Parameter childTree should be an instance of FileSystemTree');
    }

    const {
      entryName,
      entryType,
    } = childTree;

    this.children[entryName] = childTree;

    if (entryType === 'file') {
      childTree.fileBaseName = entryName.substring(0, entryName.lastIndexOf('.'));
      childTree.fileType = entryName.includes('.') ? entryName.substring(entryName.lastIndexOf('.')) : null;
    }
  }

  hasChildNamed (childName) {
    return (childName in this.children);
  }

  *[Symbol.iterator] () {
    if (this.entryType !== 'directory') {
      throw new DOMException('Cannot iterate a FileSystemTree instance wrapping a file handle, since files have no children', 'NotSupportedError');
    }

    for (const child of Object.values(this.children)) {
      yield child;
    }
  }

}
