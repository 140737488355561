import Vue from 'vue';
import { mapActions } from 'vuex';

Vue.component('region-top-bar', {

  template:  `<header class="top-bar">
                <sidebar-toggle/>
                <ul class="menu">
                  <li>
                    <p>File</p>
                    <ul>
                      <li class="option" @click="saveProject">💾 Save</li>
                      <!-- TODO: <li class="option" @click="saveProjectAs">💾 Save as</li> -->
                    </ul>
                  </li>
                </ul>

                <button class="secondary small to-dashboard" @click="$router.push({ name: 'Dashboard' })">← Back to dashboard</button>
                <!-- <undo-redo-buttons/> -->
              </header>`,

  methods: {
    ...mapActions('projectsManager', [
      'saveProject',
    ]),
  },

});