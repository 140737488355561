// A store that manages stuff in on a single project level (change pan, level, track color...)

// IDEA: Maybe install this as a submodule to the projectsManagerStore?

const mainViewDefaults = {
  component: 'tracks-overview',
  bindings: {
    // NOTE: No objects! No circular structures! Just strings!
  },
};

const sidebarViewDefaults = {
  component: 'asset-browser',
  bindings: {
    // NOTE: No objects! No circular structures! Just strings!
  },
};

export const projectStore = {

  namespaced: true,

  state: {
    // TODO: Initially get from project data
    mainView: mainViewDefaults,
    // TODO: Initially get from project data
    sidebarView: sidebarViewDefaults,
  },

  mutations: {
    // Set the project's main view.
    SET_MAIN_VIEW (state, options) {
      let mainView;

      if (options === 'default') {
        mainView = mainViewDefaults;
      }

      else {
        const { component, bindings } = options;
        mainView = {
          component,
          bindings,
        };
      }

      // TODO: Also set in project data
      state.mainView = mainView;
    },

    SET_SIDEBAR_VIEW (state, options) {
      let sidebarView;

      if (options === 'default') {
        sidebarView = sidebarViewDefaults;
      }

      else {
        const { component, bindings } = options;
        sidebarView = {
          component,
          bindings,
        };
      }

      // TODO: Also set in project data
      state.sidebarView = sidebarView;
    },

    LEVEL_CHANGE (state, { channel, newValue: newLevel }) {
      // NOTE: channel points to the same place in the memory as state.projects[projectID][group][channel.guid].
      channel.level = newLevel;
      // TODO: somehow notify Espressivo of level change
    },

    PAN_CHANGE (state, { channel, newValue: newPan }) {
      // NOTE: channel points to the same place in the memory as state.projects[projectID][group][channel.guid].
      channel.pan = newPan;
      // TODO: somehow notify Espressivo of pan change
    },

    CHANGE_TRACK_COLOR (state, { channel, newValue: newColor }) {
      if ('color' in channel) {
        channel.color = newColor;
      }
      else {
        console.warn(`Missing 'color' in channel definition. Using Vue.set().`)
        Vue.set(channel, 'color', newColor);
      }
    },
  },

};