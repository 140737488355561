import Vue from 'vue';

// undraw_security_o890.svg (modified) from https://undraw.co/
Vue.component('svg-illustration-unlock', {

  template:  `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 -60 1145.57 795">
                <defs>
                  <linearGradient id="a" x1="591.3" x2="591.3" y1="646.08" y2="107.75" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="gray" stop-opacity=".25"/>
                    <stop offset=".54" stop-color="gray" stop-opacity=".12"/>
                    <stop offset="1" stop-color="gray" stop-opacity=".1"/>
                  </linearGradient>
                  <linearGradient id="b" x1="944.34" x2="944.34" y1="854.67" y2="289.29" xlink:href="#a"/>
                </defs>
                <path fill="#6c63ff" d="M1127.43 551.22c-26.57 54.81-80.52 91.46-136.43 115.63-75.27 32.55-158.16 45.46-239.91 40q-21.49-1.43-42.85-4.57a540.44 540.44 0 01-143.27-41.72q-19.74-8.89-38.68-19.41a494.85 494.85 0 01-60.46-39.54q-7.23-5.52-14.26-11.32c-3.25-2.68-6.47-5.42-9.74-8.08-6.9-5.61-14-10.94-21.92-14.93a78.31 78.31 0 00-7.34-3.24c-25.08-9.54-54-5.94-80.11 1.5-19.86 5.66-39.22 13.41-59.06 18.94a196 196 0 01-26.91 5.72 143.21 143.21 0 01-60.46-5.31l-1.67-.52q-4.2-1.32-8.33-2.89l-1.67-.64q-4-1.54-7.83-3.31l-1.67-.76q-4-1.86-7.83-3.93c-.92-.48-1.83-1-2.73-1.49a163.67 163.67 0 01-39.16-30.49c-.33-.33-.64-.67-1-1-1.59-1.72-3.15-3.47-4.66-5.26-.56-.66-1.12-1.32-1.67-2q-2.69-3.28-5.19-6.71c-.25-.33-.49-.67-.73-1a144.59 144.59 0 01-16.86-30.49c-.14-.33-.27-.67-.39-1q-2-5.15-3.65-10.47c-.45-1.48-.88-3-1.26-4.48-.13-.44-.25-.89-.36-1.33-5.52-21.73-6.15-44.32-6.15-66.78v-7.85c.16-36.33.72-73.28 6.08-109.11 0-.25.07-.5.11-.74a340.76 340.76 0 017.31-35.82 264.25 264.25 0 0112.31-36.3c12.33-29 30.26-55.39 50.17-79.85C199.35 79.12 265.12 27.45 342.1 8.11c82.95-20.83 175.14-.87 242.05 52.4 20.4 16.24 38.73 35.47 61.64 47.92 20 10.84 43.41 15.33 64.83 6.8 17.7-7.05 29.22-10.55 49.1-9.48a411.56 411.56 0 01120.36 25.21c1.56.57 3.12 1.16 4.67 1.75 100.71 38.53 187.28 115.08 232.28 213.45q1.79 3.9 3.47 7.86c26.83 62.67 36.53 136.12 6.93 197.2z" opacity=".1"/>
                <g transform="translate(152,-50)">
                  <path fill="#f2f2f2" d="M808.04953,514.426,549.94194,481.8907c-11.6984-97.31007,5.97553-192.311,36.15031-286.78621l258.10759,32.53527C805.25206,320.32433,793.81967,415.98657,808.04953,514.426Z" transform="translate(-135.22758 -53.46492)"/>
                  <rect width="158.98061" height="17.08056" x="620.46188" y="239.32092" fill="#6c63ff" transform="rotate(7.184 1058.1627468 -855.8983003)"/>
                  <rect width="215.47786" height="8.54028" x="584.3123" y="280.00622" fill="#6c63ff" opacity=".3" transform="rotate(7.184 1050.2618381 -819.48308854)"/>
                  <rect width="215.47786" height="8.54028" x="580.86159" y="307.38127" fill="#6c63ff" opacity=".3" transform="rotate(7.184 1046.8110948 -792.10808296)"/>
                  <rect width="215.47786" height="8.54028" x="577.41087" y="334.75632" fill="#6c63ff" opacity=".3" transform="rotate(7.184 1043.3603565 -764.73299774)"/>
                  <rect width="215.47786" height="8.54028" x="573.96016" y="362.13136" fill="#6c63ff" opacity=".3" transform="rotate(7.184 1039.90969784 -737.35791752)"/>
                  <rect width="215.47786" height="8.54028" x="570.50945" y="389.50641" fill="#6c63ff" opacity=".3" transform="rotate(7.184 1036.45895454 -709.98291194)"/>
                  <rect width="215.47786" height="8.54028" x="567.05874" y="416.88146" fill="#6c63ff" opacity=".3" transform="rotate(7.184 1033.00821624 -682.60782672)"/>
                  <rect width="215.47786" height="8.54028" x="563.60803" y="444.25651" fill="#6c63ff" opacity=".3" transform="rotate(7.184 1029.55755758 -655.2327465)"/>
                  <path fill="#e6e6e6" d="M685.822,418.558l-241.47528-96.7872c13.30533-97.1034,54.44283-184.5405,107.54133-268.30587l241.47528,96.7872C732.23048,230.0652,696.96406,319.72145,685.822,418.558Z" transform="translate(-135.22758 -53.46492)"/>
                  <rect width="158.98061" height="17.08056" x="569.20294" y="124.77524" fill="#6c63ff" transform="rotate(21.842 719.62762387 -243.843377)"/>
                  <rect width="215.47786" height="8.54028" x="524.09611" y="162.27623" fill="#6c63ff" opacity=".3" transform="rotate(21.842 702.76938285 -210.61253223)"/>
                  <rect width="215.47786" height="8.54028" x="513.8308" y="187.88725" fill="#6c63ff" opacity=".3" transform="rotate(21.842 692.50408728 -185.0015004)"/>
                  <rect width="215.47786" height="8.54028" x="503.56549" y="213.49826" fill="#6c63ff" opacity=".3" transform="rotate(21.842 682.23878671 -159.3904945)"/>
                  <rect width="215.47786" height="8.54028" x="493.30018" y="239.10927" fill="#6c63ff" opacity=".3" transform="rotate(21.842 671.97346023 -133.77948358)"/>
                  <rect width="215.47786" height="8.54028" x="483.03487" y="264.72029" fill="#6c63ff" opacity=".3" transform="rotate(21.842 661.70816466 -108.16845176)"/>
                  <rect width="215.47786" height="8.54028" x="472.76956" y="290.3313" fill="#6c63ff" opacity=".3" transform="rotate(21.842 651.44283817 -82.55744085)"/>
                  <rect width="215.47786" height="8.54028" x="462.50425" y="315.94232" fill="#6c63ff" opacity=".3" transform="rotate(21.842 641.1775376 -56.94643494)"/>
                  <path fill="#f2f2f2" d="M626.10441,441.86819H365.95432c-23.7765-95.083-18.12247-191.54842,0-289.05566H626.10441C599.05388,249.64034,599.6751,345.98129,626.10441,441.86819Z" transform="translate(-135.22758 -53.46492)"/>
                  <rect width="158.98061" height="17.08056" x="270.80036" y="128.91011" fill="#6c63ff"/>
                  <rect width="215.47786" height="8.54028" x="239.26702" y="170.29763" fill="#6c63ff" opacity=".3"/>
                  <rect width="215.47786" height="8.54028" x="239.26702" y="197.88931" fill="#6c63ff" opacity=".3"/>
                  <rect width="215.47786" height="8.54028" x="239.26702" y="225.48098" fill="#6c63ff" opacity=".3"/>
                  <rect width="215.47786" height="8.54028" x="239.26702" y="253.07266" fill="#6c63ff" opacity=".3"/>
                  <rect width="215.47786" height="8.54028" x="239.26702" y="280.66434" fill="#6c63ff" opacity=".3"/>
                  <rect width="215.47786" height="8.54028" x="239.26702" y="308.25601" fill="#6c63ff" opacity=".3"/>
                  <rect width="215.47786" height="8.54028" x="239.26702" y="335.84769" fill="#6c63ff" opacity=".3"/>
                  <path fill="#3f3d56" d="M450.74936,384.16287l-89.62488-69.67719a10.23093,10.23093,0,0,0-8.86925-5.1311H303.88286a10.23092,10.23092,0,0,0-10.23092,10.23092V746.52659a33.18514,33.18514,0,0,0,33.18514,33.18514H823.39922a33.18513,33.18513,0,0,0,33.18513-33.18514V414.32549a30.16262,30.16262,0,0,0-30.16261-30.16262Z" transform="translate(-135.22758 -53.46492)"/>
                  <!-- <rect width="90.06919" height="31.52422" x="607.39961" y="400.7086" fill="#6c63ff"/> -->
                </g>
                <g transform="translate(0, 140)">
                  <path d="M659.03 289.85h-13.55v-26.32c0-29.7-23.68-54.39-53.38-54.84a54.28 54.28 0 00-54.85 54.11v27.05h-13.52a22.62 22.62 0 00-22.55 22.55v108.22a22.62 22.62 0 0022.55 22.55h135.3a22.61 22.61 0 0022.54-22.55V312.4a22.61 22.61 0 00-22.54-22.55zm-59.75 70.35v38.76a8.09 8.09 0 01-7.5 8.12 7.91 7.91 0 01-8.28-7.9v-39a18 18 0 1115.78 0zm30.44-70.35h-76.69V262.8a38.33 38.33 0 0176.66 0z" opacity=".1"/>
                  <path fill="#6c63ff" d="M659.03 287.85h-13.55v-26.32c0-29.7-23.68-54.39-53.38-54.84a54.28 54.28 0 00-54.85 54.11v27.05h-13.52a22.62 22.62 0 00-22.55 22.55v108.22a22.62 22.62 0 0022.55 22.55h135.3a22.61 22.61 0 0022.54-22.55V310.4a22.61 22.61 0 00-22.54-22.55zm-59.75 70.35v38.76a8.09 8.09 0 01-7.5 8.12 7.91 7.91 0 01-8.28-7.9v-39a18 18 0 1115.78 0zm30.44-70.35h-76.69V260.8a38.33 38.33 0 0176.66 0z"/>
                </g>
                <g transform="translate(100, -20)">
                  <ellipse cx="120.3" cy="728.81" fill="#6c63ff" opacity=".1" rx="88.28" ry="14.18"/>
                  <path fill="#535461" d="M117.46 457.19h5.68v127.9h-5.68z"/>
                  <path fill="#3f3d56" d="M158.84 584.7l-.36 5.76-.5 8.13-.21 3.39-.5 8.14-.22 3.39-.5 8.13-5.73 92.47a15.66 15.66 0 01-15.63 14.7h-29.78a15.65 15.65 0 01-15.62-14.7l-5.76-92.47-.5-8.13-.21-3.39-.49-8.16-.21-3.39-.5-8.13-.36-5.76a8.11 8.11 0 018.1-8.61h60.89a8.1 8.1 0 018.09 8.63z"/>
                  <path fill="#9d9cb5" d="M158.48 590.46l-.5 8.13H82.61l-.5-8.13h76.37zM157.77 601.98l-.5 8.14H83.33l-.51-8.14h74.95zM157.05 613.51l-.5 8.13h-72.5l-.51-8.13h73.51z"/>
                  <path fill="#6c63ff" d="M73.44 505.13c33.08 23.93 46.86 61.37 46.86 61.37s-39.87-1.37-73-25.3S.44 479.83.44 479.83s39.91 1.37 73 25.3z"/>
                  <path fill="none" stroke="#535461" stroke-miterlimit="10" stroke-width="2" d="M.49 479.83s41.38 23 54.65 42 65.16 44.68 65.16 44.68"/>
                  <path fill="#6c63ff" d="M94.79 456.96c18 13 25.51 33.4 25.51 33.4s-21.7-.74-39.71-13.77-25.5-33.4-25.5-33.4 21.7.69 39.7 13.77z"/>
                  <path fill="none" stroke="#535461" stroke-miterlimit="10" stroke-width="2" d="M55.09 443.14s22.52 12.5 29.74 22.85 35.47 24.32 35.47 24.32"/>
                  <path fill="#6c63ff" d="M146.47 489.28c-20.69 22.73-25.06 52.43-25.06 52.43s29.16-7.15 49.84-29.88 25.06-52.44 25.06-52.44-29.16 7.15-49.84 29.89z"/>
                  <path fill="none" stroke="#535461" stroke-miterlimit="10" stroke-width="2" d="M196.31 459.39s-26.94 23.3-33.79 39.36-41.11 43-41.11 43"/>
                </g>
              </svg>`,

});