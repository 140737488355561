import Vue from 'vue';
import { mapGetters } from 'vuex';

Vue.component('sidebar-toggle', {

  template:  `<div class="sidebar-toggle-container button-container">
                <span
                  class="sidebar-toggle"
                  :class="activeClass"
                  @click="toggleSidebar"
                ></span>
              </div>`,

  methods: {
    toggleSidebar () {
      this.sidebarVisible = !this.sidebarVisible;
    },
  },

  computed: {
    ...mapGetters('settings', [
      'getAppSetting',
    ]),

    activeClass () {
      return (this.sidebarVisible === true) ? 'active' : 'inactive';
    },

    sidebarVisible: {
      get () {
        return this.getAppSetting('layout', 'sidebarVisible');
      },

      set (newValue) {
        this.$store.commit('settings/CHANGE_APP_SETTING', {
          settingPath: ['layout', 'sidebarVisible'],
          newValue,
        });
      },
    },
  },

});