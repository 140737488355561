import Vue from 'vue';
import { FileSystem } from '../../system/FileSystem.js';

Vue.component('asset-folder', {

  props: {
    'assetName': {
      type: String,
      required: true,
    },
    'assetDirectoryTreeStructureItem': {
      type: Object,
      required: true,
    },
    // 'collapsed': {
    //   type: Boolean,
    //   required: false,
    //   default: false,
    // },
  },

  // REVIEW: Experiment with structure, this feels illogical

  template:  `<ul class="asset-folder-contents">
                <li
                  v-for="(assetItem, assetName) of assetDirectoryTreeStructureItem.children"
                  class="option"
                >
                  <asset-file
                    v-if="isFile(assetItem) && assetItem.fileType === '.wav'"
                    :assetName="assetName"
                    :assetFileTreeStructureItem="assetItem"
                  />
                  <template v-else-if="isDirectory(assetItem)">
                    <span class="asset-folder-name">📁 {{ assetName }}</span>
                    <asset-folder
                      :assetName="assetName"
                      :assetDirectoryTreeStructureItem="assetItem"
                    />
                  </template>
                </li>
              </ul>`,

  methods: {
    isFile (assetItem) {
      return FileSystem.isFileTreeStructureItem(assetItem);
    },

    isDirectory (assetItem) {
      return FileSystem.isDirectoryTreeStructureItem(assetItem);
    },

    // getComponentName (assetItem) {
    //   if (FileSystem.isFileTreeStructureItem(assetItem)) {
    //     return 'asset-file';
    //   }
    //   else if () {
    //     return 'asset-folder';
    //   }
    //   else {
    //     console.warn('Asset item is no file nor directory, then what is it?!', assetItem);
    //   }
    // },
  },

});