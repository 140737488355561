import Vue from 'vue';
import { eventHub } from '../../event-hub.js';
import { EventHelper } from '../../helpers/EventHelper.js';
import { mapState } from 'vuex';

const minimumDistanceFromViewportEdge = 10; // px

Vue.component('context-menu', {

  // TODO: Second time opening context menu without closing, mouseup on right mouse button doesn't work

  debug: false,
  debugTag: 'ContextMenu',
  debugColor: '#FF7043',

  props: {
    'event': {
      validator (value) {
        // contextmenu event is always an instance of MouseEvent
        // IDEA: Other types of events may trigger this as well. What if we have a three dots menu for example? Then TouchEvents should be supported as well.
        return value instanceof MouseEvent;
      },
    },
    'options': {
      type: Object,
      default: {},
    },
  },

  template:  `<transition
                name="fade"
                appear
                mode="out-in"
                :css="true"
                @enter="transitionEnter"
              >
                <div
                  :key="JSON.stringify(origin)"
                  class="context-menu"
                  :class="contextMenuClasses"
                  :style="contextMenuStyles"
                >
                  <ul
                    v-if="options"
                    v-for="optionGroup of options"
                    class="context-menu-option-group"
                  >
                    <template v-for="option of optionGroup">
                      <li
                        class="context-menu-option"
                        :class="getOptionClasses(option)"
                        :data-name="option.label.toLowerCase()"
                        tabindex="-1"
                        :is="option.component || 'context-menu-option'"
                        v-bind="option.componentBindings"
                        :option="option"
                        :pickingInProgress.sync="pickingInProgress"
                        @picked="closeSelf"
                      ></li>
                    </template>
                  </ul>
                </div>
              </transition>`,

  created () {
    document.body.classList.add('context-menu-open');

    // If this component is being debugged, add element that shows the boundaries that
    // make sure the context menu flips vertically or horizontally if it doesn't fit.
    if (this.debug === true && this.$localDebug === true) {
      document.body.appendChild(this.debugStyleTag);
    }

    this.addListeners();

    // Goal: remove/add mouseevent listeners at just the right moments
    document.addEventListener('contextmenu', this.preSwitchContextMenu, { capture: true, passive: true });

    // Goal: close self if contextmenu is triggered on element and that didn't open this component, but the native context menu
    // capture: false, so only triggered if no other elements stopped the bubbling of this event
    document.addEventListener('contextmenu', this.closeSelf, { capture: false, passive: true });

    // Goal: close self on resize
    window.addEventListener('resize', this.closeSelf, { capture: false, passive: true });
  },

  destroyed () {
    document.body.classList.remove('context-menu-open');

    // If this component is being debugged, remove element that shows the boundaries that
    // make sure the context menu flips vertically or horizontally if it doesn't fit.
    if (this.debug === true && this.$localDebug === true) {
      this.debugStyleTag.remove();
    }

    this.removeListeners();
    document.removeEventListener('contextmenu', this.preSwitchContextMenu, { capture: true, passive: true });
    document.removeEventListener('contextmenu', this.closeSelf, { capture: false, passive: true });
    window.removeEventListener('resize', this.closeSelf, { capture: false, passive: true });

    // Allow parent component to clean up after the context menu is destroyed
    this.$emit('destroyed');
  },

  watch: {
    // Right after context menu is called again while it was already open,
    // reattach event listeners.
    event () {
      this.$warn(`Prop 'event' changed –> user is switching context menu`);

      document.addEventListener('mouseup', (event) => {
        this.$log('Captured mouseup event after contextmenu event to reattach event listeners');
        event.preventDefault();
        event.stopPropagation();
        this.postSwitchContextMenu();
      }, { capture: true, passive: false, once: true });
    },
  },

  data () {
    const debugStyleTag = document.createElement('style');
    debugStyleTag.innerHTML = `body::after {
                                content: '';
                                pointer-events: none;
                                position: fixed;
                                left: ${minimumDistanceFromViewportEdge}px;
                                top: ${minimumDistanceFromViewportEdge}px;
                                bottom: ${minimumDistanceFromViewportEdge}px;
                                right: ${minimumDistanceFromViewportEdge}px;
                                border: 1px solid red;
                              }`;

    return {
      pickingInProgress: false,
      positionStyles: {},
      positionSet: false,
      debugStyleTag,
    };
  },

  computed: {
    ...mapState('system', [
      'debug',
    ]),

    origin () {
      const origin = {
        x: this.event.clientX,
        y: this.event.clientY,
      };

      return origin;
    },

    contextMenuStyles () {
      return {
        ...this.positionStyles,
      };
    },

    contextMenuClasses () {
      return {
        'has-icons': this.hasIcons === true,
      };
    },

    hasIcons () {
      try {
        let foundAtLeastOneIcon = false;
        for (const optionGroup of Object.values(this.options)) {
          for (const option of optionGroup) {
            if ('icon' in option && option.icon !== null) {
              foundAtLeastOneIcon = true;
            }
          }
        }
        return foundAtLeastOneIcon;
      }

      catch (err) {
        return false;
      }
    },
  },

  methods: {
    transitionEnter (el, done) {
      this.setPosition(el, this.origin);
      done();
    },

    preSwitchContextMenu () {
      this.$log('––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––');
      this.$log('Captured contextmenu event to remove/add event listeners at just the right moments');
      this.removeListeners();
    },

    postSwitchContextMenu () {
      this.$log('Reattaching listeners');
      this.addListeners();
    },

    addListeners () {
      this.$log('Adding listeners');
      document.addEventListener('mousedown', this.documentMousedown, { capture: true, passive: false });
      document.addEventListener('click', this.documentClick, { capture: true, passive: false });
      document.addEventListener('mouseup', this.documentMouseup, { capture: true, passive: false });
    },

    removeListeners () {
      this.$log('Removing listeners');
      document.removeEventListener('mousedown', this.documentMousedown, { capture: true, passive: false });
      document.removeEventListener('click', this.documentClick, { capture: true, passive: false });
      document.removeEventListener('mouseup', this.documentMouseup, { capture: true, passive: false });
    },

    setPosition (el, origin) {
      const { x, y } = origin,
            positionX = (x + el.clientWidth > window.innerWidth - minimumDistanceFromViewportEdge) ? { right: `${window.innerWidth - x}px` } : { left: `${x}px` },
            positionY = (y + el.clientHeight > window.innerHeight - minimumDistanceFromViewportEdge) ? { bottom: `${window.innerHeight - y}px` } : { top: `${y}px` };

      this.positionStyles = {
        ...positionX,
        ...positionY,
      };
    },

    getOptionClasses (option) {
      return {
        'disabled': 'disabled' in option && option.disabled === true,
        'special': 'component' in option && option.component,
      };
    },

    closeSelf () {
      this.pickingInProgress = false;
      eventHub.$emit('destroyContextMenu');
    },

    documentMousedown (event) {
      // If user does mousedown with left mouse button...
      if (EventHelper.usedMouseButton('left', event)) {
        // and this happened on an element that is not this context menu or any of its descendants...
        if (!EventHelper.happenedOnElementOrDescendant(this.$el, event)) {
          this.$log('Mousedown outside context menu');
          // make sure the click handlers or default actions of
          // other elements on the same position won't get fired.
          event.preventDefault();
          event.stopPropagation();
          // We don't destroy the context menu here yet! This will be done in the click handler,
          // to prevent other click event listeners from still executing their handlers.
        }
      }
    },

    documentClick (event) {
      // If user clicks with left mouse button...
      if (EventHelper.usedMouseButton('left', event)) {
        // and this happened on an element that is not this context menu or any of its descendants...
        if (!EventHelper.happenedOnElementOrDescendant(this.$el, event)) {
          this.$log('Click outside context menu, closing self');
          // make sure the click handlers or default actions of
          // other elements on the same position won't get fired.
          event.preventDefault();
          event.stopPropagation();

          // Because the click is now completed, we want to destroy the context menu immediately.
          this.closeSelf();
        }
      }
    },

    documentMouseup (event) {
      // If user does mouseup with right mouse button...
      if (EventHelper.usedMouseButton('right', event)) {
        // and this happened on an element that is not this context menu or any of its descendants...
        if (!EventHelper.happenedOnElementOrDescendant(this.$el, event)) {
          // and this didn't happen within 20px of the context menu...
          if (!EventHelper.happenedNearElement(this.$el, event, 20)) {
            this.$log('Mouseup outside context menu, closing self');
            // make sure the mouseup handlers or default actions of
            // other elements on the same position won't get fired.
            event.preventDefault();
            event.stopPropagation();

            // We want to close the context menu in this case, leaving it
            // open wouldn't feel the same as native context menus.
            this.closeSelf();
          }
        }
      }
    },
  },

});