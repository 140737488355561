import Vue from 'vue';
import { undoRedoHistory } from '../../store/plugins/undo-redo-history.js';

Vue.component('undo-redo-buttons', {

  template:  `<div class="undo-redo-buttons button-container">
                <button @click.prevent="undo" title="Undo" class="undo">&#x293A;</button>
                <button @click.prevent="redo" title="Redo" class="redo">&#x293A;</button>
              </div>`,

  methods: {
    undo () {
      undoRedoHistory.undo();
    },

    redo () {
      undoRedoHistory.redo();
    },
  }
});