import Vue from 'vue';
import { ContextMenuOptionMixin } from '../../mixins/ContextMenuOptionMixin.js';
import { colors } from '../../variables/colors.js';

Vue.component('context-menu-color-picker', {

  mixins: [
    ContextMenuOptionMixin,
  ],

  props: [
    'activeColor',
  ],

  template:  `<li>
                <span
                  v-for="color of colorList"
                  class="track-color-option"
                  :class="{ 'active' : color === activeColor }"
                  :style="{ 'background-color': 'var(--color--' + color + ')' }"
                  @click.left.capture.stop="executeAction($event, false, color)"
                  @mouseup.right.capture.stop="executeAction($event, true, color)"
                ></span>
              </li>`,

  data () {
    return {
      colorList: colors,
    };
  },

  methods: {
    async executeAction (originalEvent, delayBeforeClosing = false, color) {
      const { option } = this;

      // If option was disabled, block click
      if (this.optionDisabled()) return;

      if (this.pickingInProgress === true) {
        console.warn(`Another option was already being picked. Blocking click on '${option.label}'.`);
        return;
      }

      this.$emit('update:pickingInProgress', true);

      if (delayBeforeClosing === true) {
        // If this all went well, focus on the option element, so we can make it look
        // like the option was clicked with CSS. Stay there for a few ms, then proceed.
        // We use 'currentTarget', because 'target' may differ, depending on which element
        // the user clicked inside the option. 'currentTarget' is always the option element.
        originalEvent.currentTarget.focus();
        await this.$wait(100);
      }

      // Then do the actual execution of the action
      option.action(originalEvent, color);

      // Always close after executing an action
      this.$emit('picked');
    },
  },

});