import { FileSystem } from '../../system/FileSystem.js';
import { IDBStorage } from '../../system/IDBStorage.js';
import { SystemLogger } from '../../system/SystemLogger.js';
import { config } from '../../config.js';

export const systemStore = {

  namespaced: true,

  state: {
    ...config,
    supportChecks: {
      hasFSA: FileSystem.hasFSA,
      hasIndexedDB: IDBStorage.hasIndexedDB,
    },
    buttonTexts: {
      surprised: ['Oh sick', 'Cool', 'Nice', 'Awesome', 'Great thanks', 'Amazing', 'Wow', 'Insane', 'Splendid', 'Far out', 'Good to know', 'No way'],
      understanding: ['Ah I see', 'Ok thanks!', 'Gotcha.', 'Now I get it', 'Muchas gracias'],
      agreeable: ['Ok', 'Sure', 'Fine'],
      cancel: ['Never mind', 'No thanks', 'I changed my mind'],
      disappointed: ['Oh, okay', 'Hmm', 'Too bad', 'Fine'],
    },
    devicePixelRatio: window.devicePixelRatio,
  },

  getters: {
    supportedBrowser ({ supportChecks }) {
      return Object.values(supportChecks).every(supported => supported === true);
    },

    getRandomButtonText: ({ buttonTexts }) => (type) => {
      const options = buttonTexts[type];
      return options[Math.floor(Math.random() * options.length)];
    },
  },

  mutations: {
    LOG_STATUS (state, status) {
      SystemLogger.log(status);
    },

    UPDATE_DEVICE_PIXEL_RATIO (state, value) {
      state.devicePixelRatio = value;
    },
  },

};

export function systemPlugin (store) {
  let mq;

  function setMq () {
    mq = window.matchMedia(`(resolution: ${store.state.system.devicePixelRatio}dppx)`);
  }

  function updateStore () {
    store.commit('system/UPDATE_DEVICE_PIXEL_RATIO', window.devicePixelRatio);
    mq.removeListener(updateStore);
    setMq();
    mq.addListener(updateStore);
  }

  setMq();

  // The listener below only fires whenever the “match status” of the media query above changes.
  // e.g. when the media query above is initially is “(resolution: 2dppx)”, it will trigger the listener
  // when window.devicePixelRatio becomes 1, but it will not trigger again if it becomes 1.1 after that.
  // It will however be triggered again as soon as it becomes 2.
  // To “fix” this, we remove the listener once it is triggered, we update the media query to contain
  // the new (current) window.devicePixelRatio, then we add back the listener again, et voilà!
  mq.addListener(updateStore);
}