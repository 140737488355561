export class GeneralHelpers {

  static wait (ms) {
    return new Promise(async resolve => {
      await setTimeout(resolve, ms);
    });
  }

  static debounce (fn, time) {
    let timeout;

    return function () {
      const functionCall = () => fn.apply(this, arguments);

      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    }
  }

  static capitalize (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

}