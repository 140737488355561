import Vue from 'vue';
import { mapState } from 'vuex';

Vue.component('unsupported-browser-warning', {

  template:  `<div class="unsupported-browser-warning">
                <svg-illustration-lost/>
                <p>Unfortunately, it looks like the browser you're currently using isn't supported at this time. For now, please use the latest version of Google Chrome or Chromium to use {{ appName }}.</p>
                <p>{{ appName }} is built with brand new web techniques that make this app feel fresh. It allows editing files directly on your computer, which drastically improves loading times and enables you to sync your projects with your favorite cloud storage service. That also makes sure that, instead of dealing with hosting large audio files, we can focus on creating the best app for you to work with.</p>
              </div>`,

  computed: {
    ...mapState('system', [
      'appName',
    ]),
  },

});