// import './registerServiceWorker';
import Vue from 'vue';
import VueModal from 'vue-js-modal';
import VueFormGenerator from 'vue-form-generator';
import { CustomVuePlugin } from './custom-vue-plugin.js';
import App from './App.vue';
// import { Espressivo } from '/espressivo/dist/espressivo.js';

// System
import { store } from './store.js';
import { router } from './router.js';

// Custom libs
import './components/VueTable.js';

// Dashboard components
import './components/dashboard/DashboardActions.js';
import './components/dashboard/RecentProjects.js';
import './components/dashboard/ProjectTeaser.js';

// Project components
import './components/project/RegionTopBar.js';
import './components/project/RegionSidebar.js';
import './components/project/RegionMain.js';
import './components/project/RegionFooter.js';
import './components/project/UndoRedoButtons.js';
import './components/project/SidebarToggle.js';
import './components/project/AssetBrowser.js';
import './components/project/EventBrowser.js';
import './components/project/TracksOverview.js';
import './components/project/AssetFolder.js';
import './components/project/AssetFile.js';
import './components/project/EventTeaser.js';
import './components/project/EventFull.js';
import './components/project/AudioTrack.js';

import './components/LoadingScreen.js';
import './components/UnsupportedBrowserWarning.js';

// UI elements
import './components/ui-elements/ContextMenu.js';
import './components/ui-elements/ContextMenuOption.js';
import './components/ui-elements/ContextMenuColorPicker.js';
import './components/ui-elements/ResizeGrabber.js';
import './components/ui-elements/Macro.js';
import './components/ui-elements/Spinner.js';

// Icons
import './components/icons/IconClose.js';

// SVG illustrations
import './components/svg/SvgIllustrationLost.js';
import './components/svg/SvgIllustrationUnlock.js';

Vue.use(VueModal, {
  dynamic: true,
  dynamicDefaults: {
    scrollable: true,
    width: 800,
    height: 'auto',
    transition: 'modal-fade',
    overlayTransition: 'modal-fade',
  },
});

Vue.use(VueFormGenerator);

Vue.use(CustomVuePlugin, {
  colorCSSVars: [
    '--white',
    '--whitish',
    '--grey-lightest',
    '--grey-lighter',
    '--grey-medium',
    '--grey-dark',
    '--grey-darker',
    '--grey-darkest',
    '--black',
    '--primary',
    '--secondary',
  ],
});

// IDEA: Maybe make one teaser wrapper for event-teaser and all other future *-teaser components, since otherwise we'll need to create a shitload of teaser components.

// TODO:
// Vue.component('sound-container-full', SoundContainerFull);
// Vue.component('parameter-controller-full', ParameterControllerFull); // Mapping view here or stand-alone component?

Vue.config.productionTip = process.env.NODE_ENV === 'development';

// Now init Vue
new Vue({
  router,
  store,
  render: create => create(App),
}).$mount('#app');