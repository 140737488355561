import Vue from 'vue';
import { ModalCreateProject } from '../../modals/ModalCreateProject.js';
import { ModalInvalidProject } from '../../modals/ModalInvalidProject.js';

Vue.component('dashboard-actions', {

  template:  `<div class="dashboard-actions">
                <p>Create something special with {{ $store.state.system.appName }}, the app for composers and sound designers to create dynamic audio systems on the web.</p>
                <div class="buttons-wrapper buttons-wrapper--stretch">
                  <button id="new-project" @click="createNewProject" class="primary">New project</button>
                  <button id="open-project" @click="openProject" class="secondary">Open project</button>
                </div>
              </div>`,

  methods: {
    createNewProject () {
      this.$modal.show(ModalCreateProject);
    },

    async openProject () {
      try {
        const projectID = await this.$store.dispatch('projectsManager/openProject');

        // If projectID is undefined, user aborted
        // REVIEW: Factcheck statement above! This may have changed in the File System Access API.
        if (projectID !== undefined) {
          this.$router.push({
            name: 'Project loading',
            params: {
              projectID,
            },
            query: {
              unlock: true, // REVIEW: Is this still absolutely necessary?
            },
          });
        }
      }
      catch (error) {
        this.$modal.show(ModalInvalidProject, { error });
      }
    },
  },

});