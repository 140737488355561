import { EventHelper } from '../helpers/EventHelper.js';
import { mapState, mapGetters } from 'vuex';

// TODO: Can we add something here that makes it easier to execute logic when the modal is closed?
// TODO: Maybe in combination with the logic that happens when clicking a cancel button
export const ModalMixin = {

  mounted () {
    window.addEventListener('keyup', this.keyup, { passive: false, capture: false });
  },

  beforeDestroy () {
    window.removeEventListener('keyup', this.keyup, { passive: false, capture: false });
  },

  computed: {
    ...mapState('system', {
      mailHelp: state => state.mail.help,
      mailContact: state => state.mail.contact,
    }),

    ...mapGetters('system', [
      'getRandomButtonText',
    ]),
  },

  methods: {
    keyup (event) {
      const { defaultButton } = this.$refs;

      if (defaultButton && EventHelper.hasModifierKeyPressed(event) === false && event.key === 'Enter') {
        EventHelper.kill(event);
        defaultButton.click();
      }
    },
  },

};