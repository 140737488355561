import { FileSystemTree } from './FileSystemTree.js';
import { SystemLogger } from './SystemLogger.js';

export const ENTRY_ACCESSIBLE = Symbol('entry_accessible');
export const ENTRY_LOCKED = Symbol('entry_locked');
export const ENTRY_MISSING = Symbol('entry_missing');
export const ENTRY_FAILED_TO_LOAD = Symbol('entry_failed_to_load');

export class FileSystem {

  static ignoredSystemEntries = ['.DS_Store', 'Thumbs.db'];

  static hasFSA = ('showDirectoryPicker' in window); // REVIEW: Is this sufficient feature checking?

  static async chooseDirectory () {
    return window.showDirectoryPicker();
  }

  static async fillTree (currentTree) {
    const directoryHandle = currentTree.getHandle();

     try {
       for await (const [entryName, entry] of directoryHandle) {
         // Skip system entries
         if (this.ignoredSystemEntries.includes(entryName)) {
           continue;
         }

         const childTree = new FileSystemTree(entry);
         currentTree.addChild(childTree);

         // If directory, get children and add to FileSystemTree instance
         if (childTree.entryType === 'directory') {
           await this.fillTree(childTree);
         }
       }
     }
     catch (err) {
       SystemLogger.log(err);
       throw err;
     }
  }

  /**
   * Read directory contents and returns a complete tree of the directory and all of its subdirectories.
   *
   * @param {FileSystemDirectoryHandle} directoryHandle
   * @returns {FileSystemTree}
   */
  static async getTreeStructure (directoryHandle) {
    if (!(directoryHandle instanceof FileSystemDirectoryHandle)) {
      throw new TypeError('Parameter directoryHandle should be an instance of FileSystemDirectoryHandle');
    }

    const treeStructure = new FileSystemTree(directoryHandle);
    await this.fillTree(treeStructure);
    return treeStructure;
  }

  static async getFileTreeStructureItemContents (fileTreeStructureItem, file) {
    if (file === undefined) {
      file = await fileTreeStructureItem.getHandle().getFile();
    }

    if ((file instanceof File) === false) {
      throw new TypeError('Should be instance of File');
    }

    let contents;

    switch (fileTreeStructureItem.fileType) {
      case '.wav':
      case '.ogg':
        contents = await file.arrayBuffer();
        break;
      case '.espressivo':
        contents = await file.text();
        contents = JSON.parse(contents);
        break;
      default:
        contents = null;
        break;
    }

    return contents;
  }

  static convertPermissionState (permissionState) {
    switch (permissionState) {
      case 'granted':
        return ENTRY_ACCESSIBLE;
      case 'prompt':
        return ENTRY_LOCKED;
      case 'denied':
        return ENTRY_LOCKED;
    }
  }

  static async queryPermission (entryHandle) {
    const permissionState = await entryHandle.queryPermission({ mode: 'readwrite' });
    return this.convertPermissionState(permissionState);
  }

  static async requestPermission (entryHandle) {
    const permissionState = await entryHandle.requestPermission({ mode: 'readwrite' });
    return this.convertPermissionState(permissionState);
  }

  static invalidCharacters = '*."/\\[]:;|,'.split('');

  static containsInvalidCharacters (str) {
    if (typeof str !== 'string') throw new TypeError('Should be string');
    return this.invalidCharacters.some(invalidChar => str.includes(invalidChar));
  }

  static isValidEntryName (entryName) {
    if (typeof entryName !== 'string') throw new TypeError('Should be string');
    if (entryName.trim().length === 0) return false;
    if (this.containsInvalidCharacters(entryName)) return false;
    return true;
  }

  static async directoryExists (dirName, targetDir) {
    try {
      await targetDir.getDirectoryHandle(dirName, {
        create: false,
      });

      return true;
    }
    catch {
      return false;
    }
  }

  // TODO: Finish and run this before creating file with getFile(..., { create: true })
  static async fileExists (fileName, targetDir) {
    try {
      await targetDir.getFileHandle(fileName, {
        create: false,
      });

      return true;
    }
    catch {
      return false;
    }
  }

  static isFileTreeStructureItem = item => item.entryType === 'file';
  static isDirectoryTreeStructureItem = item => item.entryType === 'directory';

}