import Vue from 'vue';

Vue.component('vue-table', {

  props: [
    'tableData',
    'lanes',
    'actions',
    'filters',
    'children',
    'expandedEntries',
    'entryUniqueIDKey',
    'emptyMessage',
    'actionsRowClickable',
    'activeRow',
    'transposed', // flipped table: header becomes left column
  ],

  template:  `<div class="vue-table">
                <div v-if="filteredData.length === 0" class="messages">
                  <p>{{ emptyMessage || 'No results' }}</p>
                </div>

                <template v-else>
                  <!-- TRANSPOSED TABLE (limited functionality for now) -->
                  <table v-if="isTransposed === true" :class="{'can-contain-children' : children}">
                    <tbody v-for="laneDefinition in lanes.filter(col => !col.hide)">
                      <tr class="entry">
                        <th scope="row">{{ laneDefinition.label }}</th>
                        <td v-for="rowEntry in filteredData">
                          <!-- PRE-ACTIONS -->
                          <div v-if="actions && actions.length > 0" class="actions">
                            <div v-for="action in filterVisibleActionsForParent(actions.filter(action => (('lane' in action) && action.lane === laneDefinition.model && action.position === 'pre')))" class="action">
                              <component
                                v-if="('component' in action)"
                                :ref="action.ref"
                                :data-entry="rowEntry[entryUniqueIDKey]"
                                :is="action.component"
                                v-bind="action.componentBindings"
                                @click="actionClick(action, rowEntry)"
                              />
                              <a href="#" v-else :title="action.label" @click.prevent="actionClick(action, rowEntry)">{{ action.label }}</a>
                            </div>
                          </div>

                          <!-- CELL CONTENT -->
                          <div v-html="laneDefinition.renderFunction ? laneDefinition.renderFunction(getColumnDataFromRowEntry(rowEntry, laneDefinition.model), rowEntry) : getColumnDataFromRowEntry(rowEntry, laneDefinition.model)"></div>

                          <!-- POST-ACTIONS -->
                          <div v-if="actions && actions.length > 0" class="actions">
                            <div v-for="action in filterVisibleActionsForParent(actions.filter(action => (('lane' in action) && action.lane === laneDefinition.model) && action.position === 'post'))" class="action">
                              <component
                                v-if="('component' in action)"
                                :ref="action.ref"
                                :data-entry="rowEntry[entryUniqueIDKey]"
                                :is="action.component"
                                v-bind="action.componentBindings"
                                @click="actionClick(action, rowEntry)"
                              />
                              <a href="#" v-else :title="action.label" @click.prevent="actionClick(action, rowEntry)">{{ action.label }}</a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <!-- NORMALE TABEL -->
                  <table v-else :class="{'can-contain-children' : children}">
                    <thead>
                      <tr>
                        <th
                          v-for="laneDefinition in lanes.filter(col => !col.hide)"
                          @click="sortBy(laneDefinition)"
                          :class="{ 'is-active': sortKey == laneDefinition.model, 'sortable': laneDefinition.sortable, [laneDefinition.class]: !!laneDefinition.class }"
                          scope="col"
                        >
                          <span>{{ laneDefinition.label }}</span>
                          <span class="sort-arrow" :class="sortOrders[laneDefinition.model] > 0 ? 'desc' : 'asc'"></span>
                        </th>
                        <th v-if="actions && actions.length > 0" scope="col"><!-- column for actions, has no header --></th>
                      </tr>
                    </thead>

                    <tbody v-for="rowEntry in filteredData">
                      <tr
                        class="entry"
                        :class="{
                          'entry-has-children': rowEntry[children] && rowEntry[children].length > 0,
                          'expanded': expandedEntries && expandedEntries.includes(rowEntry[entryUniqueIDKey]),
                          'is-active': activeRow === rowEntry[entryUniqueIDKey]
                        }"
                      >
                        <td
                          v-for="laneDefinition in lanes.filter(col => !col.hide)"
                          :class="{ clickable : laneDefinition.rowclick, [laneDefinition.class]: !!laneDefinition.class }"
                          @click="(e) => { clickEntry(rowEntry, laneDefinition, e); }"
                        >
                          <div v-html="laneDefinition.renderFunction ? laneDefinition.renderFunction(getColumnDataFromRowEntry(rowEntry, laneDefinition.model), rowEntry) : getColumnDataFromRowEntry(rowEntry, laneDefinition.model)"></div>
                          <div v-if="actions && actions.length > 0" class="actions">
                            <div v-for="action in filterVisibleActionsForParent(actions.filter(action => (('lane' in action) && action.lane === laneDefinition.model)))" class="action">
                              <component
                                v-if="('component' in action)"
                                :ref="action.ref"
                                :data-entry="rowEntry[entryUniqueIDKey]"
                                :is="action.component"
                                v-bind="action.componentBindings"
                                @click="actionClick(action, rowEntry)"
                              />
                              <a href="#" v-else :title="action.label" @click.prevent="actionClick(action, rowEntry)">{{ action.label }}</a>
                            </div>
                          </div>
                        </td>

                        <td
                          class="actions"
                          v-if="actions && actions.length > 0"
                          :class="{ clickable : actionsRowClickable }"
                          @click="(e) => { clickEntry(rowEntry, {rowclick: actionsRowClickable}, e); }"
                        >
                          <div v-for="action in filterVisibleActionsForParent(actions.filter(action => !('lane' in action)), rowEntry)" class="action">
                            <component
                              v-if="('component' in action)"
                              :ref="action.ref"
                              :data-entry="rowEntry[entryUniqueIDKey]"
                              :is="action.component"
                              v-bind="action.componentBindings"
                              @click="actionClick(action, rowEntry)"
                            />
                            <a href="#" v-else :title="action.label" @click.prevent="actionClick(action, rowEntry)">{{ action.label }}</a>
                          </div>
                        </td>
                      </tr>

                      <!-- Entry children -->
                      <tr
                        class="entry-child"
                        v-if="children && expandedEntries && expandedEntries.includes(rowEntry[entryUniqueIDKey])"
                        v-for="child in rowEntry[children]"
                      >
                        <td
                          v-for="laneDefinition in lanes.filter(col => !col.hide)"
                          v-html="laneDefinition.childRenderFunction ? laneDefinition.childRenderFunction(child) : child[laneDefinition.childProperty]"
                          class="clickable"
                          @click="clickChild(rowEntry, child)"
                        ></td>

                        <td
                          class="actions"
                          v-if="actions && actions.length > 0"
                          :class="{ clickable : actionsRowClickable }"
                          @click="(e) => { clickEntry(rowEntry, {rowclick: actionsRowClickable}, e); }"
                        >
                          <div v-for="action in filterVisibleActionsForChild(actions, rowEntry, child)" class="action">
                            <component
                              v-if="('component' in action)"
                              :ref="action.ref"
                              :data-entry="rowEntry[entryUniqueIDKey]"
                              :is="action.component"
                              v-bind="action.componentBindings"
                              @click="actionClick(action, rowEntry, child)"
                            />
                            <a href="#" v-else :title="action.label" @click.prevent="actionClick(action, rowEntry, child)">{{ action.label }}</a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
              </div>`,

  data () {
    const sortOrders = {};
    let sortKey = '';

    for (const laneDefinition of this.lanes) {
      sortOrders[laneDefinition.model] = 1;

      if (laneDefinition.initialSortOrder) {
        sortKey = laneDefinition.model;

        if (laneDefinition.initialSortOrder === -1) {
          sortOrders[laneDefinition.model] = -1;
        }
      }
    }

    return {
      sortKey: sortKey,
      sortOrders: sortOrders
    };
  },

  computed: {
    isTransposed () {
      return this.transposed || false;
    },

    // TODO: translate
    // Gefilterde en gesorteerde data
    filteredData () {
      let data = this.tableData;

      if (this.filters) {
        for (const [filterId, filter] of Object.entries(this.filters)) {
          if (filter.value) {
            const filterValue = (typeof filter.value === 'object') ? String(filter.value.id.toLowerCase()) : String(filter.value.toLowerCase());

            if (filter.key) {
              // search in specific lane for value:
              data = data.filter(row => String(row[filter.key]).toLowerCase().indexOf(filterValue) > -1);
            }

            else {
              // search all lanes for value:
              data = data.filter(row => {
                return Object.keys(row).some(key => String(row[key]).toLowerCase().indexOf(filterValue) > -1);
              });
            }
          }
        }
      }

      const order = this.sortOrders[this.sortKey] || 1;

      if (this.sortKey) {
        data = data.slice().sort((a, b) => {
          a = this.getColumnDataFromRowEntry(a, this.sortKey);
          b = this.getColumnDataFromRowEntry(b, this.sortKey);

          if (typeof a === 'string') {
            return a.localeCompare(b, { sensitivity: 'base' }) * order;
          }

          else {
            return (a === b ? 0 : a > b ? 1 : -1) * order;
          }
        });
      }

      return data;
    }
  },

  methods: {
    getColumnDataFromRowEntry (rowEntry, modelKey) {
      if (modelKey !== undefined) {
        let laneData;

        for (const [depth, key] of modelKey.split('.').entries()) {
          if (depth === 0) {
            if (!(key in rowEntry)) throw `vue-table: key '${key}' doesn't exist in rowEntry`;
            laneData = rowEntry[key];
          }
          else {
            if (!(key in laneData)) throw `vue-table: key '${key}' doesn't exist in rowEntry at depth ${depth}`;
            laneData = laneData[key];
          }
        }

        return laneData;
      }

      else {
        // modelKey not defined, it's likely the rowEntry is only being used in a renderFunction
      }
    },

    filterVisibleActionsForParent (actions, rowEntry) {
      return actions.filter((action) => {
        // action is only for the 'child'
        if (('childOnly' in action) && action.childOnly === true) {
          return false;
        }
        // action is also for the 'parent'
        else {
          // if action is visible, return true. if the action has no property 'visible', assume 'true'.
          return ('visible' in action) ? action.visible(rowEntry, null, this.$parent) === true : true;
        }
      });
    },

    filterVisibleActionsForChild (actions, rowEntry, child) {
      return actions.filter((action) => {
        // action is only for the 'parent'
        if (('parentOnly' in action) && action.parentOnly === true) {
          return false;
        }
        // action is also for the 'child'
        else {
          // if action is visible, return true. if the action has no property 'visible', assume 'true'.
          return ('visible' in action) ? action.visible(rowEntry, child, this.$parent) === true : true;
        }
      });
    },

    sortBy (laneDefinition) {
      if (laneDefinition.sortable) {
        // TODO: translate
        // Bij de eerste keer klikken op een non-active kolom is this.sortKey nog niet de laneDefinition.model
        // In dat geval willen we alleen de te sorteren kolom aanpassen, niet ook de sorteerrichting
        if (this.sortKey === laneDefinition.model) {
          this.sortOrders[laneDefinition.model] = this.sortOrders[laneDefinition.model] * -1;
        }

        // TODO: translate
        // Wissel van sortKey (de te sorteren kolom)
        this.sortKey = laneDefinition.model;

        this.$emit('sort');
      }
    },

    actionClick (action, rowEntry, child) {
      this.$emit(action.event, {
        entry: rowEntry,
        child: child
      });
    },

    clickEntry (rowEntry, laneDefinition, e) {
      if (e.path) {
        var elementPath = e.path || e.composedPath && e.composedPath(),
            clickedOnAction = elementPath.find(function (element) {
          return element.classList && element.classList.contains('action');
        });
      }

      if (laneDefinition.rowclick && !clickedOnAction) {
        this.$emit('rowclick', rowEntry);
      }
    },

    clickChild (rowEntry, child) {
      this.$emit('childclick', {
        entry: rowEntry,
        child: child
      });
    }
  }
});