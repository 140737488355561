import { store } from '../store.js';
import { mapState, mapGetters } from 'vuex';

// TODO: Change toast success icon and add an error toast
export const Project = {

  name: 'project',

  // Called before the route that renders this component is confirmed.
  beforeRouteEnter (to, from, next) {
    const { projectID } = to.params;

    if (store.state.projectsManager.activeProjectID === projectID) {
      next();
    }

    // If the activeProjectID is not the projectID in the URL, we redirect to the 'Project loading' page, which then triggers loading the project.
    else {
      next({
        name: 'Project loading',
        params: {
          projectID,
        },
      });
    }
  },

  template:  `<div
                class="project-wrapper"
                :data-sidebar-state="(sidebarVisible === true) ? 'expanded' : 'collapsed'"
                :style="appStyles"
              >
                <region-top-bar/>
                <region-sidebar v-if="sidebarVisible"/>
                <region-main/>
                <region-footer/>

                <transition
                  name="slide-fade"
                  mode="in-out"
                  appear
                >
                  <div class="saving-project-toast" v-if="savingProjectToastVisible === true">
                    <template v-if="savingProjectID !== null">
                      <p>Saving project</p>
                      <loading-spinner/>
                    </template>
                    <template v-else>
                      <p>Saved</p>
                      <span class="done-temp">✅</span>
                    </template>
                  </div>
                </transition>
              </div>`,

  data () {
    return {
      savingProjectToastVisible: false,
      savingProjectToastVisibilityTimeout: null,
    };
  },

  computed: {
    ...mapGetters('settings', [
      'getAppSetting',
    ]),

    ...mapState('projectsManager', [
      'savingProjectID',
    ]),

    sidebarVisible () {
      return this.getAppSetting('layout', 'sidebarVisible');
    },

    sidebarWidth () {
      return this.getAppSetting('layout', 'sidebarWidth');
    },

    appStyles () {
      return {
        '--sidebar-width': (this.sidebarVisible === true) ? `${this.sidebarWidth}%` : '0%',
      };
    },
  },

  watch: {
    savingProjectID (newProjectID, oldProjectID) {
      if (this.savingProjectToastVisibilityTimeout) {
        clearTimeout(this.savingProjectToastVisibilityTimeout);
      }

      if (newProjectID !== null && newProjectID !== undefined) {
        this.savingProjectToastVisible = true;
      }

      else {
        setTimeout(() => {
          this.savingProjectToastVisible = false;
        }, 1500);
      }
    },
  },

};