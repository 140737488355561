import Vue from 'vue';
import { mapState } from 'vuex';

Vue.component('region-footer', {

  template:  `<footer class="footer">
                <div class="footer-branding">{{ appName }} v{{ appVersion }}</div>
              </footer>`,

  computed: {
    ...mapState('system', [
      'appName',
      'appVersion',
    ]),
  },

});