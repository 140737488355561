import Vue from 'vue';

const transparentImg = new Image();
transparentImg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';

Vue.component('resize-grabber', {

  // TODO: This now only works in full width/height layouts. How would this work for an AudioTrack for example?

  props: {
    'resizeType': {
      type: String,
      default: 'x',
      validator (value) {
        return (value === 'x' || value === 'y');
      },
    },
    'min': {
      type: Number,
      default: 0,
    },
    'max': {
      type: Number,
      default: 100,
    },
    'value': {
      type: Number,
      default: 0,
    },
  },

  template:  `<span
                class="resize-grabber"
                :class="[resizeType, { 'active': resizeGrabberDragging }]"
                @mousedown.left.prevent.stop="startResize"
              ></span>`,

  data () {
    return {
      resizeGrabberDragging: false,
    };
  },

  methods: {
    resizeSidebar (e) {
      let newValue;

      if (this.resizeType === 'x') {
        newValue = Math.max(Math.min(e.clientX / window.innerWidth * 100, this.max), this.min);
      }
      else if (this.resizeType === 'y') {
        newValue = Math.max(Math.min(e.clientY / window.innerHeight * 100, this.max), this.min);
      }

      this.$emit('update:value', newValue);
    },

    startResize (event) {
      document.addEventListener('mousemove', this.resizeSidebar, { capture: true, passive: true });
      document.addEventListener('mouseup', this.endResize, { capture: true, passive: false });
      document.addEventListener('click', this.blockClickAfterwards, { capture: true, passive: false });
      this.resizeGrabberDragging = true;
    },

    endResize (event) {
      event.preventDefault();
      event.stopPropagation();
      document.removeEventListener('mousemove', this.resizeSidebar, { capture: true, passive: true });
      document.removeEventListener('mouseup', this.endResize, { capture: true, passive: false });
      this.resizeGrabberDragging = false;
    },

    // When the click following after dragging the resize grabber,
    // we block it and clean up its own event listener.
    blockClickAfterwards (event) {
      event.preventDefault();
      event.stopPropagation();

      // Remove self
      document.removeEventListener('click', this.blockClickAfterwards, { capture: true, passive: false });
    },
  },

  watch: {
    resizeGrabberDragging (newValue) {
      document.body.classList[newValue === true ? 'add' : 'remove']('resizing', this.resizeType);
    },
  },

});