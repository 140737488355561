import Vue from 'vue';
import { eventHub } from '../../event-hub.js';
import { events as eventsFixtures } from '../../fixtures/events.js';

Vue.component('event-full', {

  props: [
    'eventID',
  ],

  // TODO: Make browsable with arrow up and down keys
  // TODO: Consider NOT using Vue Form Generator, but something custom.
  //       I will probably end up making a lot of special UX items to prevent users filling out form after form anyway.

  template:  `<div
                class="event-full"
                @contextmenu.prevent.stop="contextMenu"
              >
                <span class="close-button" @click="closeSelf"></span>
                <h2>Event “{{ eventID }}”</h2>
                <vue-form-generator
                  tag="div"
                  class="event-form"
                  :schema="schema"
                  :model="eventDefinition"
                  :options="$formOptions"
                />
              </div>`,

  data () {
    return {
      eventsFixtures,
      schema: {
        fields: [
          {
            label: 'Event ID',
            model: 'id',
            type: 'input',
            inputType: 'text',
            required: true,
            readonly: true,
            help: `This is the ID that programmers need to use to fire this event.`,
          },
          {
            label: 'Event name',
            model: 'name',
            type: 'input',
            inputType: 'text',
            required: true,
            get: (model) => {
              // FIXME: When setter is done, for a few ms, model is undefined
              return model.name;
            },
            set: (model, value) => {
              this.findFixture(this.eventID).name = value;
            },
          },
          {
            type: 'select', // TODO: VueMultiSelect
            label: 'Event type',
            multi: true, // TODO: try, because multiple responses possible
            model: 'response[0].type',
            // set (model, value) {
            //   console.log(model, value);
            // },
            values: [
              {
                name: 'Set switch',
                id: 'setSwitch'
              },
              {
                name: 'Play sound',
                id: 'playSound'
              },
              {
                name: 'Test test 123',
                id: 'testTest123'
              },
            ],
          },
        ],
      },
    };
  },

  computed: {
    eventDefinition () {
      // TODO: Temporarily still using simple fixtures, but replace this asap!
      return this.findFixture(this.eventID);
    },
  },

  methods: {
    findFixture (eventID) {
      return this.eventsFixtures.find(eventDefinition => eventDefinition.name === eventID);
    },

    closeSelf () {
      this.$store.commit('project/SET_PROJECT_MAIN_VIEW', 'default');
    },

    contextMenu (event) {
      this.$el.focus();

      eventHub.$emit('spawnContextMenu', {
        event,
        options: {},
      });
    },
  },

});