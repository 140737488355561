import { config } from './config.js';
import { GeneralHelpers } from './helpers/GeneralHelpers.js';

export const CustomVuePlugin = {

  install (Vue, { colorCSSVars = [] }) {
    Vue.prototype.$capitalize = GeneralHelpers.capitalize;
    Vue.prototype.$kebabToCamelCase = value => value.replace(/-(\w)/g, (m => m[1].toUpperCase()));

    Vue.prototype.$getCSSVariable = varName => window.getComputedStyle(document.documentElement).getPropertyValue(varName).trim();

    Vue.prototype.$colors = colorCSSVars.reduce((allColors, currentCSSVar) => {
      const colorName = Vue.prototype.$kebabToCamelCase(currentCSSVar.substring(2));
      allColors[colorName] = Vue.prototype.$getCSSVariable(currentCSSVar);
      return allColors;
    }, {});

    Vue.prototype.$wait = GeneralHelpers.wait;

    Vue.prototype.$formOptions = {
      validateAfterLoad: true,
      validateAfterChanged: true,
      validateAsync: false,
    };

    // Install global mixin '$log'
    // Each component with static property 'debugTag' or 'name' (string) is easily debuggable.
    // Logs only appear if config.debug === true and if $options.debug isn't false. Set a custom
    // color using static property 'debugColor' (CSS color code or name).
    const defaultDebugColor = '#6d3d98'; // '#dc2759'
    Vue.mixin({
      methods: {
        $prepareMessages (logs, callback) {
          if (config.debug === true && this.$localDebug === true && (('debugTag' in this.$options) || ('name' in this.$options))) {
            const messages = [`%c[${this.$options.debugTag || this.$options.name}]`];

            // This uses Array.entries to use the indexes of values
            for (const [delta, partOfLog] of logs.entries()) {
              if (delta === 0) {
                messages.push(`color: ${this.$options.debugColor || defaultDebugColor};`);

                if (typeof partOfLog === 'string') {
                  messages[0] += ` ${partOfLog}`;
                }

                else {
                  messages.push(partOfLog);
                }
              }

              else {
                messages.push(partOfLog);
              }
            }

            callback(messages);
          }
        },

        $log (...logs) {
          this.$prepareMessages(logs, (messages) => {
            console.groupCollapsed(...messages);
            console.trace();
            console.groupEnd();
          });
        },

        $warn (...logs) {
          this.$prepareMessages(logs, (messages) => {
            // Replace colored text with bold text
            messages[1] = 'font-weight: bold;';
            console.warn(...messages);
          });
        },
      },

      computed: {
        $localDebug () {
          return (this.$options.debug !== false);
        },
      },
    });

    // Register a global custom directive called “v-autofocus”
    // A value can be bound to the directive. This works like Vue's :key mechanism.
    // When the component is updated, we check if the value has changed. If it has,
    // we focus on the element once more.
    // e.g.: <div v-autofocus="id"></div> will regain focus if the value of id changes.
    Vue.directive('autofocus', {
      // inserted: when the bound element is inserted into the DOM.
      inserted (el) {
        el.focus();
      },
      // componentUpdated: when the containing component's VNode and the VNodes of its children have updated.
      // This makes sure the element regains focus if the containing component's template has been updated
      // and the directive's value has changed.
      componentUpdated (el, { value, oldValue }) {
        // Only update if the directive's value has changed.
        if (value === oldValue) return;
        el.focus();
      },
    });
  },

};