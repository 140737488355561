import Vue from 'vue';
import { eventHub } from '../../event-hub.js';
import { PixelDensityReactiveCanvasMixin } from '../../mixins/PixelDensityReactiveCanvasMixin.js';

// Fork of Canvas-nobs, originally written by Hakan Bilgin (https://github.com/hbi99/Canvas-nob)
// Rewritten by Anoesj Sadraee.
// Licensed under the Creative Commons Attribution 4.0 License

Vue.component('ui-macro', {

  mixins: [
    PixelDensityReactiveCanvasMixin,
  ],

  props: {
    'mode': {
      type: String,
      default: 'linear',
      validator (value) {
        // Value must match one of these strings
        return ['linear', 'aroundCenter'].includes(value);
      },
    },
    'value': {
      type: Number,
      required: true,
    },
    'defaultValue': {
      type: Number,
      default: 0,
    },
    'width': {
      type: Number,
      default: 60,
    },
    'mainColor': {
      type: String,
      default: '#62b6ff',
    },
    'frameColor': {
      type: String,
      default: '#444444',
    }
  },

  // @contextmenu.stop.prevent because we want make sure we show this macro's contextmenu, instead of one of its parents.
  // @click.stop because sometimes when a contextmenu event gets fired, a click event also gets fired, in which case we don't want the event to bubble up.
  template:  `<div
                class="macro"
                @contextmenu.stop.prevent="contextMenu"
                @click.stop
              >
                <canvas
                  ref="canvas"
                  :width="width * devicePixelRatio"
                  :height="width * devicePixelRatio"
                  :style="styles"
                  @selectstart.prevent.stop
                  @mousedown.left.stop="startDrag"
                  @touchstart.stop="startDrag"
                  @dblclick.capture.stop="resetToDefault"
                />
              </div>`,

  data () {
    return {
      context: null,
      pointerLockAvailable: null,
      draggedDistanceY: this.value,
      distanceToDragUntilFull: 400,
      currentlyDraggingElement: null,
      yOnDragStart: 0,
      valueOnDragStart: 0,
      prevNewVal: 0,
    };
  },

  computed: {
    styles () {
      return {
        width: `${this.width}px`,
        height: `${this.width}px`, // same as width
      };
    },

    min () {
      switch (this.mode) {
        case 'linear': return 0;
        case 'aroundCenter': return -100;
      };
    },

    max () {
      return 100;
    },

    // This is used to normalizes how fast you can turn a macro, regardless of how
    // much the range is between max and min. We need this, because we use the movement
    // of the pointer to twist the macro. With bigger ranges, you'd normally need to
    // bridge more distance to go from min to max or vice versa.
    // For every pixel your pointer moves, it counts for {dragDensity} pixels.
    dragDensity () {
      return (this.max - this.min) / 100;
    },

    // Returns number between 0 and 1, which represents how much the value is
    // compared to min and max, 0 being min and 1 being max.
    percentageOfValue () {
      return (this.value - this.min) / (this.max - this.min);
    },
  },

  mounted () {
    // Check for PointerLock API
    // Moz disabled for now, because it doesn't work very well yet
    const requestPointerLock = this.$refs.canvas.requestPointerLock || this.$refs.canvas.mozRequestPointerLock,
          exitPointerLock = document.exitPointerLock || document.mozExitPointerLock;

    this.pointerLockAvailable = (requestPointerLock && exitPointerLock) ? true : false;

    this.context = this.$refs.canvas.getContext('2d');

    // Draw the macro in the canvas element
    this.draw();
  },

  methods: {
    addReleaseListeners () {
      document.addEventListener('mousemove', this.drag, { capture: true, passive: true });
      document.addEventListener('touchmove', this.drag, { capture: true, passive: false });
      document.addEventListener('mouseup', this.release, { capture: true, passive: true });
      document.addEventListener('touchend', this.release, { capture: true, passive: true });
      document.addEventListener('touchcancel', this.release, { capture: true, passive: true });

      // When pointer lock is unavailable, dragging macro up, then releasing the mouse somewhere
      // else triggers a click. We don't want that, so we block it.
      if (this.pointerLockAvailable === false) {
        document.addEventListener('click', this.blockClickAfterwards, { capture: true, passive: false });
      }
    },

    removeReleaseListeners () {
      document.removeEventListener('mousemove', this.drag, { capture: true, passive: true });
      document.removeEventListener('touchmove', this.drag, { capture: true, passive: false });
      document.removeEventListener('mouseup', this.release, { capture: true, passive: true });
      document.removeEventListener('touchend', this.release, { capture: true, passive: true });
      document.removeEventListener('touchcancel', this.release, { capture: true, passive: true });
    },

    /***********************************************
     *  Dragging / releasing
     **********************************************/

    // blockSelect (event) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   return false;
    // },

    startDrag (event) {
      if (event.defaultPrevented === true) return;
      this.addReleaseListeners();
      if (this.pointerLockAvailable === true) this.$refs.canvas.requestPointerLock();
      this.currentlyDraggingElement = event.target || event.srcElement;
      this.yOnDragStart = event.clientY || event.touches[0].clientY;
      this.valueOnDragStart = this.value;
      event.preventDefault();
    },

    // FIXME: First drag after pointerlock seems to reset the value of the macro to what seems to be the maximum value initially, but it could also just be random. Please investigate.
    drag (event) {
      if (!this.currentlyDraggingElement) return;
      let newVal;

      let isTouchEvent = false;
      try {
        isTouchEvent = event instanceof TouchEvent;
      } catch (err) {}

      // With pointerlock, determining the new value is relative. We use the pointer movement in pixels
      // and keep saving the total dragged distance, clipped between this.min and this.max.
      if (this.pointerLockAvailable === true && isTouchEvent === false) {
        let movement = event.movementY;
        if (event.shiftKey) movement /= 10;
        newVal = this.draggedDistanceY = Math.min(Math.max(this.draggedDistanceY - movement * this.dragDensity, this.min), this.max);
      }

      // Without pointerlock, determining the new value is absolute. The pointer position is constantly
      // being compared to the position where the drag started. The new value is always based on the value
      // when the drag started + the difference in pointer position (now - when we started dragging).
      else {
        const yCurrent = event.clientY || event.touches[0].clientY;
        let movement = (yCurrent - this.yOnDragStart);
        if (event.shiftKey) movement /= 10; // This won't work as smooth as with pointerlock, but then again.. people should update their browsers.
        newVal = Math.min(Math.max(this.valueOnDragStart - movement * this.dragDensity, this.min), this.max);
      }

      if (this.prevNewVal !== newVal) {
        this.$emit('update:value', newVal);
        this.prevNewVal = newVal;
      }
    },

    release () {
      if (!this.currentlyDraggingElement) return;

      if (this.pointerLockAvailable === true) document.exitPointerLock();
      this.removeReleaseListeners();
      this.currentlyDraggingElement = null;
    },

    // When the click following after dragging the macro without pointerlock happens,
    // we block it and clean up its own event listener.
    blockClickAfterwards (e) {
      e.preventDefault();
      e.stopPropagation();

      // Remove self
      document.removeEventListener('click', this.blockClickAfterwards, { capture: true, passive: false });
    },

    draw () {
      const {
        mode,
        min,
        max,
        value,
        percentageOfValue,
        context: ctx,
        mainColor,
        frameColor,
      } = this;

      const diameter = this.$refs.canvas.width,
            lineWidth = diameter * 0.07,
            radius = diameter/2,
            pi = Math.PI,
            // A full circle's length is 2π rad. 0π (or 2π) locate at 3 o'clock.
            // https://www.w3schools.com/tags/img_arc.gif
            // Our 'ring' spans 1.5π rad
            ringLengthPIRad = 1.5 * pi, // length of the ring in radials
            twelveOClock = 1.5 * pi, // top of the circle corresponds to 1.5π rad
            startPIRad  = twelveOClock - ringLengthPIRad/2,
            endPIRad    = twelveOClock + ringLengthPIRad/2,
            valuePIRad  = percentageOfValue * (endPIRad - startPIRad) + startPIRad;

      // Clear canvas and defaults
      ctx.clearRect(0, 0, diameter, diameter);
      ctx.lineWidth = lineWidth;
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';

      // Main color line
      if (mode === 'linear') {
        if (value > min) {
          // Prepare for main line
          ctx.strokeStyle = mainColor;
          ctx.beginPath();
          ctx.arc(radius, radius, (radius - lineWidth), startPIRad, valuePIRad, false);
          ctx.stroke();
        }

        // Frame line
        ctx.strokeStyle = frameColor;
        ctx.beginPath();
        ctx.moveTo(radius, radius);
        ctx.arc(radius, radius, (radius - lineWidth), valuePIRad, endPIRad, false);
        ctx.stroke();
      }

      else if (mode === 'aroundCenter') {
        const centerValue = (max + min) / 2,
              centerPIRad = (endPIRad + startPIRad) / 2;

        // If value is center or on the first half
        if (value <= centerValue) {
          // We need to draw the right half first
          ctx.strokeStyle = frameColor;
          ctx.beginPath();
          ctx.arc(radius, radius, (radius - lineWidth), endPIRad, centerPIRad, true);
          ctx.stroke();

          // Then (if not center) we draw the colored arc from center to its value (counterclockwise)
          if (value !== centerValue) {
            ctx.lineCap = 'butt';
            ctx.strokeStyle = mainColor;
            ctx.beginPath();
            ctx.arc(radius, radius, (radius - lineWidth), centerPIRad, valuePIRad, true);
            ctx.stroke();
            ctx.lineCap = 'round';
          }

          // Then finally, we draw the radius which partly covers the colored arc and then finishes the left half
          ctx.strokeStyle = frameColor;
          ctx.beginPath();
          ctx.moveTo(radius, radius);
          ctx.arc(radius, radius, (radius - lineWidth), valuePIRad, startPIRad, true);
          ctx.stroke();
        }

        // If value is on the second half
        else if (value > centerValue) {
          // We need to draw the left half first
          ctx.strokeStyle = frameColor;
          ctx.beginPath();
          ctx.arc(radius, radius, (radius - lineWidth), startPIRad, centerPIRad, false);
          ctx.stroke();

          // Then we draw the colored arc from center to its value (clockwise)
          ctx.lineCap = 'butt';
          ctx.strokeStyle = mainColor;
          ctx.beginPath();
          ctx.arc(radius, radius, (radius - lineWidth), centerPIRad, valuePIRad, false);
          ctx.stroke();
          ctx.lineCap = 'round';

          // Then finally, we draw the radius which partly covers the colored arc and then finishes the right half
          ctx.strokeStyle = frameColor;
          ctx.beginPath();
          ctx.moveTo(radius, radius);
          ctx.arc(radius, radius, (radius - lineWidth), valuePIRad, endPIRad, false);
          ctx.stroke();
        }
      }
    },

    /***********************************************
     *  Miscellaneous
     **********************************************/

    resetToDefault () {
      this.$emit('update:value', this.defaultValue);
      this.draggedDistanceY = this.defaultValue;
    },

    contextMenu (event) {
      eventHub.$emit('spawnContextMenu', {
        event,
        options: {
          'group-actions': [
            {
              icon: '↺',
              label: 'Reset to default',
              action: () => {
                this.resetToDefault();
              },
            },
          ],
        },
      });
    },
  },

  watch: {
    'value' () {
      this.draw();
    },
  },

});