import { mapState, mapGetters } from 'vuex';
import { ResettableComponentMixin } from '../mixins/ResettableComponentMixin.js';
import { ModalProjectMissing } from '../modals/ModalProjectMissing.js';
import { ModalCouldNotLoadProject } from '../modals/ModalCouldNotLoadProject.js';
import { ModalUnknownError } from '../modals/ModalUnknownError.js';
import { SystemLogger } from '../system/SystemLogger.js';

// TODO: Show versions dialog

export const ProjectLoading = {

  name: 'project-loading',

  mixins: [
    ResettableComponentMixin,
  ],

  initialComponentState: {
    projectDirectoryReadWritable: null,
    unlockingProject: false,
  },

  template:  `<loading-screen
                v-if="projectNeedsUnlocking === false"
                class="project-loading"
                :loadingText="currentLoadingState"
                :spinnerVisible="somethingIsLoading"
              />
              <div
                v-else
                class="project-unlock"
              >
                <svg-illustration-unlock/>

                <details>
                  <summary>In order to allow access to this folder, we need to prompt you for permission.</summary>
                  {{ appName }} is built upon technology that allows us to read and write to files and folders on your computer. For security reasons, browsers require us to prompt for permission.
                </details>

                <div class="buttons-wrapper buttons-wrapper--centered">
                  <button @click="$router.push({ name: 'Dashboard' })" class="secondary">← Back to dashboard</button>
                  <button @click="requestProjectDirectoryPermission" class="primary" v-autofocus>Unlock project<template v-if="unlockingProject">&nbsp;<loading-spinner/></template></button>
                </div>
              </div>`,

  computed: {
    ...mapState('system', [
      'appName',
    ]),

    ...mapGetters('projectsManager', [
      'currentLoadingState',
      'projectAvailable',
      'projectReadWritable',
    ]),

    // TODO: This component is a bit weird structure-wise. Can't we do something like this for proper reactivity?
    // projectDirectoryReadWritable () {
    //   return this.projectReadWritable(this.projectID);
    // },

    somethingIsLoading () {
      return this.currentLoadingState !== null;
    },

    projectID () {
      return this.$route.params.projectID;
    },

    projectNeedsUnlocking () {
      return this.projectDirectoryReadWritable === false;
    },
  },

  watch: {
    // projectID is the project name, but encoded
    projectID: {
      immediate: true,
      async handler (newProjectID, oldProjectID) {
        // Reset component data
        this.resetComponentState();

        // If this component was loaded with query unlock = true
        if (oldProjectID === undefined && this.$route.query.unlock === true) {
          // Remove query
          this.$router.replace({
            name: 'Project loading',
            params: {
              projectID: newProjectID,
            },
          });
          // First check for read/write permission, so template is updated accordingly
          await this.checkIsProjectDirectoryReadWritable();
          // Now directly show request permission prompt
          await this.requestProjectDirectoryPermission();
        }
        else {
          // Check if project directory is writable
          await this.checkIsProjectDirectoryReadWritable();
        }
      },
    },

    projectDirectoryReadWritable: {
      immediate: true,
      handler (newVal) {
        switch (newVal) {
          case true:
            this.setActiveProject();
            break;
          case false:
            // TODO: error handling
            break;
        }
      }
    },
  },

  methods: {
    async checkIsProjectDirectoryReadWritable () {
      SystemLogger.log('Checking if project directory is read-/writable');
      this.projectDirectoryReadWritable = this.projectReadWritable(this.projectID);
      SystemLogger.log('Permission query returned: %s', this.projectDirectoryReadWritable);
    },

    async requestProjectDirectoryPermission () {
      this.unlockingProject = true;
      await this.$store.dispatch('projectsManager/requestProjectDirectoryPermission', this.projectID).catch(this.projectWritabilityErrorHandler);
      await this.checkIsProjectDirectoryReadWritable();
      this.unlockingProject = false;
    },

    projectWritabilityErrorHandler (error) {
      this.$warn(error);

      // TODO: Handle this by throwing custom errors in the projectsManager store. Either with DOMException or by extending Error with our own Error types.

      let modalComponent;
      // FIXME: Finish this
      if (false) {
        // TODO: ...
        modalComponent = ModalProjectMissing; // maybe rename error modals to ModalError{WhateverErrorThisIs}
        // In this statement, we want completely different logic. On close, we want to return to Dashboard
      }
      else {
        modalComponent = ModalUnknownError; // maybe rename error modals to ModalError{WhateverErrorThisIs}
      }

      this.$modal.show(modalComponent, { error });

      // Keep old writable status
      // FIXME: Review all scenarios this error handler is used in. Maybe in some cases, we don't want to do this. Right now it works in every error scenario, i.e. if a project doesn't exist, this will be null and BSOD will show after modal is closed.
      return this.projectDirectoryReadWritable;
    },

    async setActiveProject () {
      const { projectID } = this;
      try {
        console.log('setting active project');
        await this.$store.dispatch('projectsManager/setActiveProject', projectID);
        console.log('set as active project, now redirecting');
        this.$router.replace({
          name: 'Project',
          params: {
            projectID,
          },
        });
      }
      catch (error) {
        this.$warn(error);

        let modalComponent;
        if (this.projectAvailable(projectID)) {
          modalComponent = ModalCouldNotLoadProject;
        }
        else {
          modalComponent = ModalProjectMissing;
        }

        this.$modal.show(modalComponent, { error },
          {
            name: modalComponent.name,
          },
          {
            'closed': (event) => {
              this.$router.replace({ name: 'Dashboard' });
            },
          },
        );
      }
    },
  },

};