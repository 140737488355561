<template>
  <div id="app">
    <unsupported-browser-warning v-if="supportedBrowser === false"/>

    <loading-screen
      v-else-if="initiallyGettingAvailableProjects === true"
      :loadingText="currentLoadingState"
    />

    <template v-else>
      <router-view/>

      <context-menu
        v-if="contextMenuVisible"
        v-bind="contextMenuProps"
        @destroyed="contextMenuDestroyed"
      />

      <!-- Dialogs -->
      <modals-container/>
    </template>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { config } from './config.js';
  import { eventHub } from './event-hub.js';

  export default {
    created () {
      eventHub.$on('spawnContextMenu', (props) => {
        this.contextMenuProps = props;
        this.contextMenuVisible = true;
      });

      eventHub.$on('destroyContextMenu', () => {
        this.contextMenuVisible = false;
      });

      this.$store.dispatch('projectsManager/initiallyGetAvailableProjects');
    },

    computed: {
      ...mapGetters('system', [
        'supportedBrowser',
      ]),

      ...mapState('projectsManager', [
        'initiallyGettingAvailableProjects',
      ]),

      ...mapGetters('projectsManager', [
        'currentLoadingState',
      ]),
    },

    data () {
      return {
        // Context menu
        contextMenuVisible: false,
        contextMenuProps: {},
      };
    },

    methods: {
      // This is called when the context menu is destroyed
      contextMenuDestroyed () {
        this.contextMenuProps = {};
      },
    },
  }
</script>

<style lang="scss">
  @import '@/scss/styles.scss';
</style>