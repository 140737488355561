import Vue from 'vue';
import { eventHub } from '../../event-hub.js';
import { MacroContainer } from '../../mixins/MacroContainer.js';

// TODO: Add refresh button or refresh tree structure every minute or something
Vue.component('asset-browser', {

  mixins: [
    MacroContainer,
  ],

  template:  `<nav
                class="asset-browser"
                @contextmenu.prevent.stop="contextMenu"
              >
                <h3 class="title">Assets</h3>
                <asset-folder
                  assetName="Assets folder"
                  :assetDirectoryTreeStructureItem="assets"
                />

                <div class="fader">
                  <span>Preview level</span>
                  <ui-macro
                    mode="linear"
                    :value.sync="previewLevel"
                    :defaultValue="90"
                    :width="30"
                    :mainColor="$colors.secondary"
                    :frameColor="$colors.greyDarker"
                  />
                  <span class="value-display">{{ getLevelDisplay(previewLevel) }}</span>
                </div>
              </nav>`,

  data () {
    return {
      // TODO: Temporary fix
      channelDef: {
        'name': 'Preview',
        // 'guid': '3519eeaf-e46f-495b-899e-e26de4b89594',
        // 'color': 'fern',
        'level': 0.74,
        // 'pan': -1,
        // 'inserts': []
      },
    };
  },

  computed: {
    assets () {
      return this.$store.state.projectsManager.activeProjectDirectoryTreeStructure.children.Assets;
    },

    previewLevel: {
      get () {
        return this.levelToRange(this.channelDef.level);
      },

      set (newValue) {
        this.$store.commit('project/LEVEL_CHANGE', {
          channel: this.channelDef,
          newValue: this.rangeToLevel(newValue),
        });
      }
    },
  },

  methods: {
    contextMenu (event) {
      eventHub.$emit('spawnContextMenu', {
        event,
        options: {
          'group-actions': [
            // {
            //   icon: '✎',
            //   label: 'New event',
            //   action: (event) => {
            //     this.newEvent();
            //   },
            // },
            // {
            //   icon: '📁',
            //   label: 'New folder',
            //   action: (event) => {
            //     this.newFolder();
            //   },
            // },
          ],
        },
      });
    },

    // newEvent () {
    //   console.log('New event');
    // },

    // newFolder () {
    //   console.log('New folder');
    // },
  },

});