import Vue from 'vue';
import { mapState } from 'vuex';

Vue.component('region-main', {

  props: [
    'currentProject',
  ],

  template:  `<main class="main">
                <component
                  :is="mainView.component"
                  v-bind="mainView.bindings"
                />
              </main>`,

  computed: {
    ...mapState('project', [
      'mainView',
    ]),
  },

});