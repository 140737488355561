import Vue from 'vue';
import { mapState } from 'vuex';
import moment from 'moment';

Vue.component('recent-projects', {

  name: 'recent-projects',

  template:  `<div class="recent-projects">
                <h1>Recent projects</h1>

                <ul v-if="Object.keys(recentProjects).length">
                  <li
                    is="project-teaser"
                    v-for="(projectData, projectID) of recentProjects"
                    :key="projectID"
                    :projectID="projectID"
                  />
                </ul>

                <h3 v-else class="empty">No recent projects found</h3>
              </div>`,

  data () {
    return {
      amountOfRecentProjectsShown: 10,
    };
  },

  computed: {
    ...mapState('projectsManager', [
      'availableProjects',
      'gettingAvailableProjects',
    ]),

    recentProjects () {
      // Sort by last saved date (latest first)
      return Object.fromEntries(
        Object.entries(this.availableProjects)
          // .sort(this.sortByDateDesc)
          .slice(0, this.amountOfRecentProjectsShown)
      );
    },
  },

  methods: {
    // Last saved first
    // FIXME: Won't work right now, because lastSave will be undefined.
    sortByDateDesc (
      [projectIDA, { lastSave: lastSaveA }],
      [projectIDB, { lastSave: lastSaveB }],
    ) {
      return moment(lastSaveB).diff(moment(lastSaveA));
    },
  },

});